@import "../variables.scss";

.details-page {

    strong {
        color: $text_secondary_color;
    }

    .hide {
        display: none !important;
    }

    .blog-dot-list {
        li {
            list-style-type: disc !important;
            
        }
        li::marker {
            color:$orange_color;
        }
    }

    .blog-list {
        li {
            list-style-type: decimal !important;
        }
    }

    .img-section {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
        .posts {
            padding: 25px !important;

            img {
                width: 100vw !important;
                margin-left: calc(calc(100% - 100vw) / 2) !important;
            }
        }
    }

    .details-h2 {
        text-align: left !important;
        margin: 35px 0 !important;
    }

    h3 {
        text-align: left !important;
        margin: 2px 0 !important;
        margin-bottom: 25px !important;
        max-width: fit-content !important;
        font-size: 1.1rem !important;
    }

    .title {
        margin-top: 50px;
        width: 100%;
    }

    .title-data {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        margin-left: 50px;
        align-items: center;
        justify-content: start;

        &>div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 15px;

            img {
                height: 28px;
                width: 28px;
                margin-right: 15px;
            }
        }
    }

    .posts {
        flex-direction: column;
        padding: 50px;
        padding-top: 0px;
        row-gap: 0 !important;

        p {
            max-width: 100%;
        }

        .gold {
            color: $text_color_gold !important;
            margin-top: 25px;
            font-size: 1.8rem !important;
        }

        .gold-s {
            color: $text_color_gold !important;
            font-weight: bold;
        }

        .m-b-0 {
            margin-bottom: 0 !important;
        }

        .red {
            color: #931b0e;
            font-weight: bold;
        }

        img {
            width: 100%;
            margin: 25px 0;
        }

        .img-s-vert {
            width: 50%;
            max-height: 500px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .img-s {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            max-height: 65vh;
            object-fit: contain;
        }

        .img-m {
            width: 65%;
            margin-left: auto;
            margin-right: auto;
        }

        h2 {
            max-width: 100%;
        }
    }
}