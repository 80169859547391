$text_secondary_color: #5E2A00;
$text_secondary_color_light: #906D50;
$text_primary_color: #040404;
$text_color_gold: #B88847;
$text_color_light: #FFFFFF;
$text_color_footer_orange: #FFC280;
$text_color_header: #F1ECE7;
$text_color_footer: #cbcbcb;
$tex_color_green: #355645;
$text_color_accent: #9A4502;
$text_color_placeholder: #505050;

$background_color_light: #FEEDE2;
$background_color_cookie: #fbdcc7;
$background_color_profile: #F1ECE7;
$background_color_transparent: #8080800f;
$background_color_dark: #040404;
$background_color_gradient: #F4E7DD;
$header_background_color_light: #04040448;
$background_color_strikethrough: #D8AF6D;
$background_color_white: rgba(255, 255, 255, 0.95);

$gradient_color_dark: #B88746;
$gradient_color_light: #FDF5A6;
$box_shadow_light: #9b470274;
$orange_color: #FE9800;
$border_color_orange: #FFC280;
$border_color_dark: #040404;
$border_color_accent: #9A4502;
$border_color_light: #DEDEDE;
$border_color_orange: #FECB7F;
$border_color_green: #54C12E;

$button_background_color_light: #9B4602;
$button_background_color_white: rgba(255, 255, 255, 0.8);
$button_background_color_dark: #5E2A00;
$button_background_color_dark_hover:#5e2a0012;
$button_background_color_accent: #F0EBE5;
$button_background_color_accent_light: rgb(255, 251, 246);

$input_background_color: #F5F5F5;

$main_padding_side: 7vw;

$break_point_mobile: 991px;
$background_break_point_2k: 2540px;
$background_break_point_hd: 1920px;
$background_break_point_720: 1280px;
$background_break_point_mobile_big: 911px;
$background_break_point_mobile: 700px;

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Bold.otf') format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Medium.otf') format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Book.otf') format("truetype");
    font-weight: normal;
}

@mixin bgimage($name, $big_mobile: false) {
    background-blend-mode: multiply;
    @include bgImageOverlay($name + ".webp");
    @media screen and (max-width: $background_break_point_hd){
        @include bgImageOverlay($name + "_hd.webp")
    }

    @if($big_mobile) {
        @media screen and (max-width: $background_break_point_mobile_big){
            @include bgImageOverlay($name + "_mobile.webp")
        }
    }
    @else {
        @media screen and (max-width: $background_break_point_mobile){
            @include bgImageOverlay($name + "_mobile.webp")
        }
    }
    
}

@mixin bgImageOverlay($name) {
    background:
        linear-gradient(to top, rgb(4, 4, 4),rgba(4, 4, 4, 0) 15%),
        linear-gradient(to right, $background_color_gradient 0%, transparent 50%),
        linear-gradient(to left, $background_color_gradient, transparent 50%),
        url($name) top/cover;
}

@mixin mainBgImageOverlay($name) {
    background:
        linear-gradient(to bottom, $background_color_gradient 0%, transparent 50%),
        linear-gradient(to right, $background_color_gradient 0%, transparent 50%),
        linear-gradient(to left, $background_color_gradient, transparent 50%),
        url($name) top/cover;
}

@mixin mainBgImage($name, $bottom) {
    background-blend-mode: multiply;

    @include mainBgImageOverlay($name + ".webp");
    @media screen and (max-width: $background_break_point_2k){
        @include mainBgImageOverlay($name + "_2k.webp")
    }

    @media screen and (max-width: $background_break_point_hd){
        @include mainBgImageOverlay($name + "_hd.webp")
    }

    @media screen and (max-width: $background_break_point_720){
        @include mainBgImageOverlay($name + "_720.webp")
    }

    @media screen and (max-width: $background_break_point_mobile){
        @include mainBgImageOverlay($name + "_mobile.webp")
    }

    @if($bottom) {
        &::after {
            content: "";
            width: 100vw;
            height: 92px;
            display: block;
            position: absolute;
            bottom: -30px;
            background-size: cover;
            background-position: center;
            overflow: visible;
            background-image: url("./images/backgrounds/service_page_background_bottom.png");

            @media screen and (min-width: 1921px) {
                background-image: url("./images/backgrounds/service_page_background_bottom_long.png");
            }
        }
    }
}