@import "../variables.scss";

.payment-success-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;

    .payment-success-card {
        background-color: white;
        border: 1px solid $border_color_green;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 45px;

        > img {
            width: 72px;
            height: 72px;
            margin-bottom: 15px;
        }

        h2 {
            color: $text_secondary_color;
            margin-bottom: 20px;
        }

        a {
            display: block;
            color: $text_secondary_color;
            margin-top: 40px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}