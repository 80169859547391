@import "../variables.scss";

.gallery-images {
    padding: 0 25px;
    max-width: 100vw;
    width: 100%;

    @media screen and (max-width: 460px){
        padding: 0 2px;
    }

    .small-images {
        overflow: hidden;
        margin-bottom: 50px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 5%;

        img {
            width: 138px;
            height: 134px;
            object-fit: cover;
            opacity: 0.6;
            filter: brightness(0.4);
            cursor: pointer;

            @media screen and (max-width: 690px){
                width: 50%;
                height: auto;
            }

            &:hover {
                opacity: 1;
                filter: brightness(1);
            }
        }
    }
}

.wrapper {
    .galley-header {
        display: flex;
        justify-content: center;

        .gallery-logo {
            width: 140px;
            height: 140px;
            margin-left: -6px;
            margin-top: 37px;
        }
    }

    .galley-close {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10vw;
        top: 108px;
        cursor: pointer;
    }

    @media screen and (min-width: $break_point_mobile) {
        .carousel {
            margin-top: 0 !important;
        }
    }
    @media screen and (max-width: $break_point_mobile) {
        .carousel {
            min-height: 0 !important;
            margin-top: 0 !important;
        }

        .small-images {
            margin-top: 0 !important;
        }
    }

    .carousel {
        min-height: 550px;
        margin-top: 50px;
        overflow: hidden;

        .react-multiple-carousel__arrow--left {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }

            &::before {
                background-image: url("../images/pictograms/arrow_brown.png");
                transform: rotate(90deg);
                background-size: 50px 18px;
                content: "";
                height: 18px;
                width: 50px;
                object-fit: contain;

                @media screen and (max-width: 460px){
                    margin-left: -25px;
                }
            }
        }
        .react-multiple-carousel__arrow--right {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }

            &::before {
                background-image: url("../images/pictograms/arrow_brown.png");
                transform: rotate(-90deg);
                background-size: 50px 18px;
                content: "";
                height: 18px;
                width: 50px;
                object-fit: contain;

                @media screen and (max-width: 460px){
                    margin-left: -25px;
                }
            }
        }

        .image {
            max-width: 97vw;
        }

        .react-multi-carousel-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .react-multi-carousel-item--active.slide ~ .react-multi-carousel-item--active.slide {
                padding-right: 2%;
                padding-left: 0;
        }

        .react-multi-carousel-item--active.slide {
                padding-left: 2%;
        }

        .slide {
            transition-duration: 0.5s;
            .image {
                filter:
                    blur(10px)
                    brightness(0.5) !important;
            }
        }

        .react-multi-carousel-item--active {
            padding-right: 0;
            padding-left: 0;
            transition-duration: 0.5s;
        }

        .front {
            display: flex;
            z-index: 1;

            .image {
                opacity: 1 !important;
                box-shadow: 0px 46px 95px 0px rgba(0, 0, 0, 0.39);
                filter: brightness(1) !important;
                z-index: 1;
                object-fit: none;
                transition-duration: 0.5s;
            }
        }

        .back {
            z-index: 0;

            .image {
                    opacity: 0.4 !important;
                    filter:
                        brightness(0.5);
                    transition-duration: 0.5s;
            }
        }

        .react-multi-carousel-item {
            .image {
                    opacity: 0.8;
                    filter:
                        blur(1px)
                        brightness(0.5);
                    transition-duration: 0.5s;
            }
        }
    }

    .react-multi-carousel-item {
        visibility: hidden;
    }

    .react-multi-carousel-item--active {
        visibility: visible;
    }
}