@import "../../variables.scss";

.profile-tab {
    position: relative;
    height: 100%;
    min-height: 450px;

    .profile-row{
        margin-bottom: 12px;

        .data{
            color: $text_secondary_color;
            margin-left: 12px;
        }
    }
}