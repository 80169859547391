@import "../../variables.scss";

.general-terms-component {
    font-family: "Gotham";
    line-height: 30px;

    h1 {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 100px;
        margin-top: 20px;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        color: $text_secondary_color;
    }

    h2 {
        text-align: center;
        display: block;
        width: 100%;
        margin: 55px 0;
        margin-bottom: 45px;
        font-size: 38px;
        color: $text_secondary_color;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
    }

    span {
        display: block;
        margin-bottom: 35px;
        color: $text_primary_color;
    }

    li {
        color: $text_primary_color;
        margin-bottom: 10px;
    }

    ul {
        list-style-image: url('../../images/pictograms/list_item_solid.png');
        margin-bottom: 20px;
    }
}