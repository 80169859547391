@import "../../variables.scss";

.tour-price {
    font-weight: bold;
    color: $text_secondary_color;
}

.notice-card {
    padding: 25px;
    border: 1px solid $orange_color;
    background-image: linear-gradient(180.00deg, rgba(255, 255, 255, 0.5), transparent 100%);

    .notice-header {
        color: $text_secondary_color;
        display: block;
        font-weight: bold;
        margin-bottom: 25px;
        font-style: italic;
    }
}

.private-wrapper {
    @include bgimage("./images/backgrounds/private_background");
}

.ticked-list {
    margin-bottom: 80px !important;
    margin-top: 80px !important;
    margin-left: 10% !important;

    li {
        list-style-image: url("../../images/pictograms/sub_list_item.png") !important;
    }

    @media screen and (max-width: 600px){
        & {
            margin-left: 2%;
        }
    }
}