@import "../../variables.scss";

.dummy-input {
    display: none;
}

.input-label {
    margin-bottom: 20px;
    display: block;
}

.settings-wrapper {
    width: 100%;
    height: 100%;

    .content-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        position: relative;

        >div {
            min-width: 220px;
            width: 50%;

            @media screen and (max-width: 500px) {
                width: 100% !important;
            }
        }

        .password-input {
            margin-bottom: 23px;
        }

        .delete-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 50px;
        }

        .confirm-button {
            width: 100%;
            max-width: 370px;
            height: 41px;
        }

        .delete-button {
            background-color: rgb(184, 0, 0);
            color: white;
            border-radius: 0;
            height: 41px;

            &:hover {
                background-color: rgb(146, 0, 0);
            }
        }
    }
}

.MuiDialog-container {
    background-color: #000000d9;
}

.MuiBackdrop-root {
    background-color: unset !important;
}

.confirm-dialog {

    .MuiDialog-paper {
        width: 100%;
        border: 1px solid $orange_color;
        background-color: white;

        h2 {
            font-size: 1.5rem;
            color: $text_secondary_color;
        }

        .MuiDialogContentText-root {
            color: $text_primary_color;
        }
    }

    .MuiDialogContent-root {
        margin-bottom: 50px;
    }

    .MuiDialogActions-root {
        margin-bottom: 10px;

        .cancel-button {
            background-color: rgb(24, 24, 24);
            color: #D8AF6D !important;

            &:hover {
                background-color: rgb(17, 17, 17);
            }
        }

        .delete-button {
            background-color: rgb(184, 0, 0);
            color: white;
            margin-left: 25px !important;
            margin-right: 10px;

            &:hover {
                background-color: rgb(146, 0, 0);
            }
        }
    }
}