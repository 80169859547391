@import "../../variables.scss";

.passenger-name {
    margin-right: 50px;
}

.booking-detail-wrapper {
    display: flex;
    flex-direction: column;
    margin: 55px 0;
    align-items: center;
    justify-content: center;
    margin-left: $main_padding_side;
    margin-right: $main_padding_side;
    position: relative;

    .back-link {
        position: relative;
        align-self: flex-start;
        margin-bottom: 25px;
        cursor: pointer;

        @media screen and (min-width: 1700px) {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 18px;
            margin-top: 55px;
            margin-left: 5vw;
        }

        > img {
            transform: rotate(90deg);
            height: 10px;
            width: 16px;
            margin-right: 15px;
        }
    }

    .booking-detail-header {
        display: flex;
        align-items: center;
        justify-content: center;
        row-gap: 15px;
        flex-wrap: wrap;
        margin-bottom: 50px;

        > span {
            text-align: center;
            font-size: 36px;
            font-family: Georgia, 'Times New Roman', Times, serif;
            color: $text_secondary_color;
            line-height: 38px;
        }

        > img {
            margin: 0 25px;
            width: 50px;
            height: 15px;
        }
    }
}

.booking-details {
    border: 1px solid $orange_color;

    .sumamry-booking-data .data {
        width: 100% !important;
    }

    .date-picker-accordion {
        padding: 3% !important;
    }

    .container-card {
        background-position: top;
        margin: 0 !important;

        .MuiGrid-item {
            padding-top: 8px;
        }
    }

    .comment {
        word-wrap: break-word;
        display: block;
    }

    .email {
        word-break: break-word;
    }

    .discount {
        margin-bottom: 10px;
    }

    .cancel-button {
        height: 41px !important;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #AF0F0F;
        padding: 0;
    }

    .edit-booking-button {
        margin-left: 0 !important;
        height: 41px !important;
    }

    .buttons {
        margin-left: auto;
    }

    .discount-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 775px) {
        .passenger-contacts-row {
            padding-top: 8px !important;
        }
        
        .buttons {
            margin-left: 0;
            width: 100% !important;
            display: flex;
            flex-direction: column-reverse;

            & .cancel-button {
                width: 100% !important;
                margin: 0 !important;
            }

            & .edit-booking-button {
                width: 100%;
                margin: 0 !important;
                margin-bottom: 10px !important;
            }
        }
        .container-card .sumamry-booking-data .data {
            max-width: 50%;
            word-wrap: break-word;
            word-break: break-word;
        }
    }
}

.MuiDialog-container {
    background-color: #000000d9;
}

.dialog .MuiDialog-paper {
    background-color: white;
    border: 1px solid $border_color_green;
    color: $text_secondary_color;
    text-align: center;
    position: relative;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $break_point_mobile) {
        padding: 15px;
    }

    > img {
        height: 72px;
        width: 72px;
        margin-bottom: 25px;
    }

    > span {
        margin: auto;
        font-family: Georgia, 'Times New Roman', Times, serif;
        line-height: 38px;
        font-size: 38px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: inline;
        color: black;
        width: fit-content;
        margin: 0;
        cursor: pointer;
    }

    .large-secondary-text {
        font-size: 38px;
        margin-bottom: 10px;
        color: $text_secondary_color;
    }

    .small-text {
        color: $text_primary_color;
        font-size: 16px;
        font-family: "Gotham";
    }

    .confirm-button {
        max-width: 300px;
        width: 80%;
        margin: 0 auto;
        margin-top: 25px;
        height: 54px;
    }
}

.cancel-dialog .MuiDialog-paper {
    background-color: unset;
    text-align: center;
    padding: 20px;
    overflow: visible;
    width: 80%;
    max-width: 800px;
    font-family: "Gotham";
    font-size: 20px;
    color: $text_primary_color;

    @media screen and (max-width: $break_point_mobile) {
        min-width: fit-content;
    }

    .dialog-part {
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px solid $orange_color;
        position: relative;
        padding: 25px;
    }

    .cancel-warning {
        display: flex;
        text-align: start;
        background-color: white;
        width: 100%;
        padding: 25px;
        margin-bottom: 28px;
        justify-content: center;
        align-items: center;

        img {
            height: 64px;
            margin: 0 40px;
        }

        @media screen and (max-width: 500px) {
            padding: 10px;

            img {
                height: 45px;
                margin: 0 20px;
            }
        }

        
    }

    .cancel-booking-text {
        margin-bottom: 15px;
    }

    .cancel-secondary {
        color:$text_secondary_color;
        font-size: 34px;
        line-height: 38px;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    .confirm-button {
        max-width: 370px;
        width: 80%;
        margin: 0 auto;
        margin-top: 45px;
        margin-bottom: 25px;
        height: 54px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: inline;
        color: black;
        width: fit-content;
        margin: 0;
        cursor: pointer;
    }
}