@import "../variables.scss";

.payment-cancelled-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;

    .payment-cancelled-card {
        background-color: white;
        border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 45px;
        color: $text_primary_color;
        font-size: 16px;
        font-family: "Gotham";
        
        .payment-cancelled-header {
            font-size: 38px;
            font-family: "Georgia";
            color: $text_secondary_color;
            margin-bottom: 20px
        }

        .payment-cancelled-link {
            color: $text_secondary_color;
        }
    }
}