.password-input {
    position: relative;
    max-width: 370px;

    .text-input {
        padding-right: 45px;
    }

    img {
        position: absolute;
        right: 25px;
        top: 21px;
        cursor: pointer;
    }
}