@import "../../variables.scss";

.longdistance-wrapper {
    @include bgimage("./images/backgrounds/longdistance_background");
}

.longdistance-list {
    
    ul{
        width: 50%;        
        min-width: 400px;
    }
    margin-bottom: 100px;

    @media screen and (max-width: 400px) {
        ul{
            min-width: 95%;
        }
    }
}

.vehicles-section {
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    img {
        width: 271px;        
    }

    .top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 100px;
        position: relative;
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 100px;
    }

    @media screen and (max-width: 607px) {
        img {
            width: 95% !important;
        }
    }
    @media screen and (min-width: 608px) {
        img {
            &:hover {
                transform: scale(1.5);
                z-index: 999;
                position: relative;
                left: 0;
            }
        }
    }
}