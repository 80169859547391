@import "../variables.scss";
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  margin: 0;
  width: 100%;
  background-color: white;
  background-size: cover;
  line-height: 30px !important;
  font-family: "Gotham";
}

h1 {
  color: $text_color_header;
  font-size: 61px;
  font-weight: 400;
  line-height: 1.3;
  span {
    padding: 4px 20px;
    background-color: $header_background_color_light;
  }
}

.MuiMenuItem-root:hover {
  background-color: $button_background_color_accent !important;
}

.Mui-selected {
  background-color: $button_background_color_accent_light !important;
}

.MuiPaper-root  {
  border-radius: 0 !important;
}

.MuiSnackbar-root {
  bottom: 40vh !important;
  justify-content: center !important;
  right: 0 !important;
}

.MuiSnackbar-root .MuiAlert-root {
  min-height: 150px !important;
  align-items: center !important;
  background-color: white;
  border: 1px solid $border_color_light;
  color: $text_primary_color;
  box-sizing: border-box;
  background-image: none;
  box-shadow: 0px 27px 99px 0px rgba(0, 0, 0, 0.16);

  a {
    color: $text_secondary_color;
  }
}

.checkbox {
  color: $button_background_color_dark;

  .MuiIconButton-label {
    color: $button_background_color_dark;
  }

  .MuiTouchRipple-root {
    color: $button_background_color_dark;
  }

  &:hover {
    background-color: $button_background_color_dark_hover !important;
  }
}

.pac-container {
  z-index: 9999;
}

.small-gold-text {
  color: $text_color_gold;
  font-size: smaller;
}

.gold-text {
  color: #D8AF6D;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.primary-button {
  background: linear-gradient(175.53deg, $button_background_color_light 17.754%, $button_background_color_dark 107.623%);
  color: white !important;
  width: fit-content;
  min-width: fit-content;
  font-family: "Gotham";
  padding: 5px 70px !important;
  text-decoration: none !important;
  font-weight: normal !important;
  box-shadow: 0 10px 15px $box_shadow_light;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }
}

.secondary-button {
  color: $text_secondary_color;
  font-family: "Gotham";
  background-color: white;
  padding: 5px 70px !important;
  width: fit-content;
  min-width: fit-content;
  border: none;
  text-decoration: none !important;

  &:hover {
    filter: brightness(0.9);
  }
}

.accent-button {
  color: black;
  font-family: "Gotham";
  background-color: $button_background_color_accent;
  padding: 5px 70px !important;
  width: fit-content;
  min-width: fit-content;
  border: none;
  text-decoration: none !important;

  &:hover {
    filter: brightness(0.9);
  }
}

#root {
  width: 100%;
  color: $text_primary_color;
  font-family: "Gotham";
  font-size: 16px;
  background-color: $background_color_light;
}

.container-width {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.background1 {
  overflow: auto;
  background-repeat: no-repeat;
  background-position: center;
  @include mainBgImage("./images/backgrounds/main_background", true);
  background-clip: content-box;
  background-size: cover;
  min-height: 100vh;
  height: fit-content;
}

.none {
  clear:both;
  display: none;
}

.car {
  width: 100%;
  height: 100%;
  max-height: 450px;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

.open-button {
  display: block;
  margin-top: 30px;
  font-size: 22px;
  width: 20vw;
  height: 70px;
  font-weight: 500;
  max-width: 350px;
  color: $text_primary_color !important;
  background-color: $button_background_color_white !important;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.row * {
  margin: 10px;
}

.results {
  margin: 50px;
  text-align: center;
  justify-content: center;
}

.devider {
  width: 40vw;
}

.result {
  text-align: center;
  display: inline-block;
  width: calc(100%/3);
}

.result:hover {
  background-color: lightgray;
}

#origin-oneway, #origin-bythehour, #destination-oneway, #destination-bythehour, #email-input, #name-input{
  flex-grow: 2;
  background-color: white;
  border: 1px solid transparent;
  font-size: 1.1em;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 10px;
  max-width: 400px;
  min-width: 260px;
}

.tabcontent {
  display: none;
  border-top: none;
  background-image: url("../images/backgrounds/booking_form_background.png");
  background-size: 100% 100%;
  background-clip: border-box;
  border-radius: 40px;
  width: 70% !important;
  margin: auto 0;
  margin-top: -15px;
  z-index: 10;
  position: relative;
  padding: 25px;

  @media screen and (max-width: 815px) {
    width: 90% !important;
  }
}

.error {
  border: 2px solid red !important;
}

// .preferences-container {
//   padding-top: 80px;

//   .pref-row {
//     display: flex;
//     align-items: center;

//     .MuiCheckbox-root {
//       color: #D8AF6D;
//     }
//     .MuiCheckbox-root:hover {
//       background-color: #d8af6d15;
//     }
//   }
// }

.initial-height {
  min-height: 100vh;
  position: relative;

  .jump-screen-button {
    content: url("../images/pictograms/arrow_light.png");
    position: absolute;
    bottom: 57px;
    left: calc(50% - 42px);
    cursor: pointer;
  }
}

.accordions {
  padding-top: 30px !important;  
  padding: 0 10%;

  @media screen and (max-width: $break_point_mobile) {
    display: none;

    &.mobile {
      padding: 0 !important;
      padding-bottom: 65px !important;
      margin-top: 50px;
      display: flex;
      height: 97vh;

      @media screen and (max-height: 700px) {
        .homepage-card-content {
          padding: 10px !important;

          button {
            margin-top: 15px !important;
          }
        }

        h2 {
          font-size: 1.7rem !important;
          margin: 15px !important;
        }

        .title-devider {
          margin-bottom: 10px !important;
        }
      }

      .homepage-card-wrapper {
        margin: 0 !important;
      }

      .react-multi-carousel-dot--active {
        display: flex;
        align-items: center;

        & button {
          background-color: $orange_color !important;
          width: 16px !important;
          height: 16px !important;
        }
      }

      .react-multi-carousel-dot button {
        width: 10px;
        height: 10px;
        border: none;
        background-color: #707070;
      }
    }
  }

  @media screen and (min-width: $break_point_mobile) {
    &.mobile {
      display: none;
    }
  }
}

.main-screen {
  margin: 0 $main_padding_side;
  margin-top: 85px;
}

.slogen {
  color: white;
  font-size: 4vw;
  font-weight: normal;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: fit-content;
    
  span {
    background-color: $header_background_color_light;
    width: fit-content;
    display: inline-block;
  }
}

.fixed-places-list {
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  background-color: white;
  color: $text_primary_color;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $border_color_light;
  box-shadow: 0px 27px 99px 0px rgba(0, 0, 0, 0.16);
  font-size: 13px;
  font-family: "Gotham";
  overflow-y: auto;
  
  .closed {
    display: none;
  }

  .opened-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.main img {
      width: 30px;
      height: 25px;
      object-fit: none;
    }

    .header {
      font-weight: bold;
      color: $text_color_accent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
      background-color: unset  !important;

      span {
        cursor: pointer;
      }

      img.list-item {
        margin-left: auto;
      }

      .back-img {
        margin-right: 20px;
        width: 15px;
        height: 8px;
        transform: rotate(-90deg);
      }
    }

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 20px;
      text-align: start;
      position: relative;

      &:hover {
        background-color: $button_background_color_accent;
      }

      span {
        cursor: default;
      }
    }

    &.seaport-list {
      & > div:not(.header):hover::after {
        content: "";
        display: block;
        width: 20px;
        height: 17px;
        background-image: url("../images/pictograms/seaport_list_item_filled.png");
        margin-right: 5px;
        background-repeat: no-repeat;
        min-width: fit-content;
      }
    }

    &.airport-list {
      & > div:not(.header):hover::after {
          content: "";
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../images/pictograms/airport_list_item_filled.png");
          margin-right: 5px;
          background-repeat: no-repeat;
          min-width: fit-content;
      }
    }

    &.railway-list {
      & > div:not(.header):hover::after {
        content: "";
        display: block;
        width: 17px;
        height: 17px;
        background-image: url("../images/pictograms/railway_list_item_filled.png");
        background-repeat: no-repeat;
        min-width: fit-content;
    }
    }
  }

}

.home-about-wrapper {
  background-image: url('../images/backgrounds/background_abstract_long_new.webp');
  background-color: $background_color_light;
  background-position: top;
  background-size: 100vw 200vh;
  border: unset !important;
  overflow: hidden !important;
}

.small-accordions {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  padding-bottom: 30px;
  margin-top: 30px;

  @media screen and (min-width: $break_point_mobile) {
    margin-top: 60px;
    padding-bottom: 60px;

    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: $break_point_mobile){
    display: none;

    &.mobile {
      padding: 0 !important;
      margin-bottom: 30px;
      display: flex;

      .react-multi-carousel-dot--active {
        display: flex;
        align-items: center;

        & button {
          background-color: $orange_color !important;
          width: 16px !important;
          height: 16px !important;
        }
      }

      .react-multi-carousel-dot button {
        width: 10px;
        height: 10px;
        border: none;
        background-color: #707070;
      }
    }
  }
}

.why-simber-card {
  margin: 0 10%;
  padding-bottom: 100px;
  padding: 0 10%;

  .why-simber-header {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-size: 38px;
    color: $text_secondary_color;
    font-weight: 400 !important;
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 50px;
    margin-bottom: 30px;
  }

  .why-simber-main-text {
    display: inline-block;
    margin-bottom: 25px;
  }

  .why-simber-list {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $break_point_mobile){
      flex-direction: column;
    }

    .why-simber-list-text {
      width: 30%;
      display: flex;

      @media screen and (max-width: $break_point_mobile){
        width: 100%;
        padding-bottom: 25px;
      }

      &::before {
        padding-top: 3px;
        display: block;
        margin-right: 10px;
        content: url('../images/pictograms/list_item.png');
      }
    }
  }  
}

.services {
  padding: 0 10%;
  margin: 0 10%;
  margin-top: 90px;

  .sevices-header {
    display: block;
    color: $text_secondary_color;
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-size: 38px;
    font-weight: 400 !important;
    margin-bottom: 25px;
  }

  .sevices-detail-text {
    margin-bottom: 25px;
    display: block;
  }

  .services-card {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, transparent 100%);
    border: 1px solid $border_color_orange;
    padding: 5% 10%;
    display: flex;
    flex-wrap: wrap;
    min-width: 240px;

    @media screen and (max-width: 778px) {
      flex-direction: column;
    }

    .services-card-row {
      flex-basis: 50%;
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 98px;
        height: 75px;
        min-width: 98px;
        min-height: 75px;
        margin-right: 25px;
        margin-left: 15px;
        object-fit: none;
        padding: 0;
      }

      >span {
        display: flex !important;
        align-items: center;
      }

      .services-card-text {
        display: block;
      }
    }
  }

  .services-after-text {
    display: block;
    color: $text_secondary_color;
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-weight: 400;
    font-size: 33px;
    line-height: 50px;
    margin-top: 50px;
  }
  padding-bottom: 90px;
}

.tastemonials {
  overflow: hidden;
  height: fit-content;
  min-height: 600px;

  .read-more {
    text-align: center;
    margin-top: 45px;

    a {
      color: #D8AF6D;
    }
  }

  @media screen and (max-width: $break_point_mobile) {
    margin-top: 30px;
  }

  .tastemonials-arrow-right {
    cursor: pointer;
    height: 20px;
    transform: rotate(-90deg);
    margin-left: auto;
  }

  .tastemonials-arrow-left {
    cursor: pointer;
    transform: rotate(90deg);
    margin-right: auto;
    height: 20px;
  }

  .tastemonials-header {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    font-size: 38px;
    color: $text_secondary_color;
    font-family: Georgia, 'Times New Roman', Times, serif !important;
  }

  .react-multi-carousel-item {
    min-height: 375px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .react-multi-carousel-item--active {
    justify-content: flex-end;
  }

  .react-multi-carousel-item--active:has(.tastemonial-card.open) {
    justify-content: center;
  }

  .react-multi-carousel-item--active ~ .react-multi-carousel-item--active {
    justify-content: flex-start;
  }

  .react-multiple-carousel__arrow--left {
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }

    &::before {
        background-image: url("../images/pictograms/arrow_brown.png");
        transform: rotate(90deg);
        background-size: 50px 18px;
        content: "";
        height: 18px;
        width: 50px;
        object-fit: contain;

        @media screen and (max-width: 460px){
            margin-left: -30px;
        }
    }
  }
  .react-multiple-carousel__arrow--right {
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }

    @media screen and (max-width: 460px){
      right: 0;
    }

    &::before {
      background-image: url("../images/pictograms/arrow_brown.png");
      transform: rotate(-90deg);
      background-size: 50px 18px;
      content: "";
      height: 18px;
      width: 50px;
      object-fit: contain;

      @media screen and (max-width: 460px){
          margin-right: -15px;
      }
    }
  }

  .tastemonial-card {
    width: 95%;
    max-width: 540px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, transparent 100%);
    margin: auto 30px;
    padding: 35px 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: fit-content;

    .tastemonial-text {
      display: inline;
      margin-bottom: 40px;
      overflow: hidden;
      height: auto;
    }

    .tastemonial-name {
      font-size: large;
      font-weight: 500;
      color: $text_secondary_color;
    }    
    
    img {
      margin-top: 15px;
      width: 97px;
      height: 18px;
    }

    &.open {
      border-top: 2px solid $border_color_orange;
      border-bottom: 2px solid $border_color_orange;

      .tastemonial-text {
        transition: all 4s ease-in-out;
        max-height: 500px;
        transition: max-height 0.25s ease-in;
        opacity: 1;
      }

      .tastemonial-name {
        font-weight: 900;
        font-size: 24px;
        color: $text_secondary_color;
      }      

      img {
        width: 160px;
        height: 28px;
      }
    }

    &.closed {
      border-top: none;
      border-bottom: none;
      width: 65%;
      height: 140px;
      margin: 0;
      padding: 0;

      .tastemonial-text { 
        max-height: 0;
        transition: max-height 0.15s ease-out;
        opacity: 0;
      }
    }
  }
}

.green {
  padding: 0 10%;
  margin: 0 10%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: $break_point_mobile){
    margin: 0;
    margin-top: 20px;
  }

  img {
    object-fit: contain;
    margin: 0 !important;
    height: auto;
    width: 100%;
  }

  .green-text {
    display: block;
    margin: 30px 0;
  }

  .green-text-small {
    display: block;
    color: $tex_color_green;
    font-weight: bold;
  }

}

.gallery {
  padding-top: 50px;

  .gallery-texts-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    margin: 0 10%;
    align-items: center;

    @media screen and (max-width: $break_point_mobile) {
      padding: 0 10% !important;
      margin: 0 !important;
    }

    .gallery-header {
      font-family: Georgia, 'Times New Roman', Times, serif !important;
      font-size: 2rem;
      margin-bottom: 25px;
      color: $text_secondary_color;
    }

    .gallery-text {
      display: block;
      width: 100%;
    }

    .gallery-text-gold {
      display: block;
      width: 100%;
      color: $text_secondary_color;
      font-size: 35px;
      line-height: 50px;
      font-family: Georgia, 'Times New Roman', Times, serif !important;
      margin: 25px 0;
    }
  }

  .gallery-images {
    margin-top: 50px;
    height: 110vh;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    position: relative;
    
    img {
      max-height: 279px;
      flex-basis: 19%;
      flex-shrink: 1;
      flex-grow: 1;
      object-fit: cover;
      object-position: bottom;
      cursor: pointer;
    }
  }

  .gallery-button-wrapper {
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    height: 272px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.65), transparent 100%);

    button {
      bottom: 50px;
      min-width: 226px;
      position: absolute;
      left: calc(50% - 113px);
      box-shadow: none;
    }
  }
}

.react-tel-input {
  width: 100%;
  height: 56px;
  margin-bottom: 20px;
  border-radius: 0;

  .form-control {
      width: 100% !important;
      height: 56px !important;
      border-radius: 0 !important;
      border: 1px solid $border_color_dark !important;
      font-size: 16px;
      padding-left: 78px !important;

      &:focus {
        box-shadow: none !important;
      }
  }

  .flag-dropdown {
      background-color: white !important;
      border: none !important;
      border-radius: 0 !important;
      border: 1px solid $border_color_dark !important;
      border-right: none !important;
      padding-left: 20px !important;
      padding-right: 20px !important;

      .country-list {

        .search-box {
          padding: 3px 8px 5px;
        }

        .search-emoji {
            display: none;
        }

        .search-box {
            width: 95%;
        }

        @media screen and (min-width: 400px) {
            margin: 2px 15px;
        }
      }

      .selected-flag {
          border: none !important;
          background-color: transparent !important;

          .flag {
            transform: scale(1.8);
          }

          .arrow {
            border: none !important;
            background-image: url("../images/pictograms/arrow_orange.png");
            width: 10px;
            height: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
      }
  }
}

.covid-mobile {
  display: none;
}

.type-cards {
  display: flex;
  column-gap: 26px;
  row-gap: 26px;
  width: 80%;
  max-width: 1120px;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  padding: 25px 0;

  .type-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 450px;
    max-width: 345px;
    width: 90%;
    font-size: 14px;
    font-family: "Gotham";
    color: $text_primary_color;
    padding: 28px;
    padding-top: 0;
    position: relative;

    &.selected {
      border: 6px solid $orange_color;

      > button {
        background-image: unset;
        background-color: $button_background_color_dark;
      }

      .continue-button {
        text-decoration: none;
        color: $text_color_gold;
        position: absolute;
        bottom: 75px;
        margin: auto;
        cursor: pointer;
        
        > img {
          transform: rotate(-90deg);
          width: 12px;
          height: 8px;
        }
      }
    }

    &.unselected {

      img,button {
        opacity: 0.2;
      }

      * {
        opacity: 0.5;
      }
    }

    > img {
      height: 176px;
      max-width: 100%;
      object-fit: contain;
    }

    button {
      width: 100%;
      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: inherit;
        }
      }
    }

    .type-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 65%;
      line-height: 24px;
      padding-bottom: 30px;

      .type-card-header {
        font-size: 16px;
        font-weight: bold;
        color: $text_secondary_color;
        margin-bottom: auto;
      }

      .type-card-name {
        font-weight: bold;
      }
    }
  }

  .more-vehicles-button {
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;

    span {
      color: $text_secondary_color;
      cursor: pointer;
    }

    button {
      display: flex;
      column-gap: 15px;
      cursor: pointer;
      justify-content: center; 
      align-items: center;

      > img {
        width: 15px;
        height: 7px;
        cursor: pointer
      }
    }
  }
}

.search-modal-wrapper {
  backdrop-filter: blur(10px);
}

.search-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  overflow: auto;

  &.types {
    align-items: flex-start;

    > img {
      position: absolute;
      top: 6%;
      right: 5%;
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
  }

  .hide {
    display: none !important;
  }

  .search-bar {
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 90%;
    max-width: 1250px;
    position: relative;
    font-family: "Gotham";
    padding: 55px;
    padding-top: 0;

    .search-bar-close {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: end;

      img {
        width: 24px;
        height: 24px;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .search-bar-header {
      font-family: "Georgia";
      font-size: 38px;
      color: $text_secondary_color;
      text-align: center;
      width: 100%;
      text-wrap: wrap;
    }

    .no-booking {
      height: 100%;
      width: 100%;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
      z-index: 1000;
      position: absolute;
      left: 0;

      a {
        color: $text_color_gold;
      }
    
      span {
        color: rgb(255 0 0);
      }
    }

    .tabs {
      display: flex;
      justify-content: center;
      width: 100%;
      height: fit-content;
      margin: auto;
      margin-top: 15px;
      z-index: 100;
      position: relative;
    
      .tablink {
        background-color: white;
        padding: 15px 7px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 18px;
        color: $text_secondary_color;
        width: 50%;
        text-transform: uppercase;
        color: $text_primary_color;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        opacity: 0.2;
    
        > span {
          margin-left: 10px;
        }

        img {
          filter: brightness(0);
        }

        > * {
          position: relative;
          top: 0px;
        }

        &.closed {
          img,
          > * {
            transition: 0.3s;
          }
        }

        &.active {
          transition: 0.3s;
          opacity: 1;
          color: $text_secondary_color;
          border-top: 2px solid $orange_color;
          border-left: 2px solid $orange_color;
          border-right: 2px solid $orange_color;
  
          img {
            filter: brightness(1);
          }
  
          > * {
            top: 15px;
            transition: 0.3s;
          }
        }
      }
    }

    .booking-card {
      background-color: white;
      flex-grow: 1;
      border: 2px solid $orange_color;
      border-top: none;
      width: 100%;
      margin-top: -2px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 50px;
      padding-bottom: 30px;
      row-gap: 15px;

      //top-border
      &:before {
        content : "";
        position: absolute;
        right: unset;
        left: 0;
        top: 0;
        height: 2px;
        width: 50%;
        border-bottom: 2px solid $orange_color;
      }

      &.oneway:before {
        right: 0;
        left: unset;
      }

      .top-row {
        display: flex;
        text-align: center;
        
        height: fit-content;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 18px;
        row-gap: 15px;
      
        > input {
          height: 55px !important;
          border: 1px solid $border_color_dark;
        }

        .search-input-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;
          min-width: 260px;
          flex-grow: 3;
        
          .clear-input-button {
            color: grey;
            position: absolute;
            right: 25px;
            top: 20px;
            cursor: pointer;
          }

          .search-input {
            padding: 0 20px;
            outline: none !important;
            height: 55px !important;
            border: 1px solid $border_color_dark;
            color: $text_color_accent;
          }
        }

        .hour-select {
          width: 200px;
          height: 55px;
          padding: 0 20px;
          border: 1px solid $border_color_dark !important;
          color: $text_color_accent;
          border-radius: 0;
          outline: none !important;
          box-shadow: none !important;

          .MuiInput-input {
            background-color: unset !important;
          }
        }
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        margin-top: 10px;

        .search-button {
          min-width: 260px;
        }

        .offer-button {
          margin-right: 15px;
          margin-left: auto;
          white-space: nowrap;
          text-transform: none !important;
          
          span {
            min-width: fit-content;
          }
        }
        
        .offer-text {
          color: $text_primary_color;
          text-align: center;
          font-size: 13px;
          padding-right: 8px;
        }
      }
    }
  }

  .login-wrapper {
    margin: auto;

    .home-login-dialog {
      overflow: hidden;
      padding: 0 20%;
      padding-top: 20px !important;
      padding-bottom: 40px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border: 1px solid $orange_color;
      width: 90vw;
      max-width: 635px;

      .login-dialog-close-button {
        display: none;
      }

      .forgot-pass-success-tick {
        width: 75px;
        height: 75px;
        margin-top: 10px;
      }
    
      input {
        outline: none;
        padding: 0 15px;
      }
    
      .eighteen {
        align-self: flex-start;
      }
    
      .home-login-header {
        color: $text_secondary_color;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        font-size: 38px;
        margin-top: 50px;
        margin-bottom: 20px;

        &.email-header {
          margin-top: 20px !important;
        }
      }
    
      .home-login-text-input {
        height: 54px;
        background-color: white;
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid $border_color_dark;
        color: $text_color_accent;
      }
    
      .home-login-sub-header {
        margin-bottom: 35px;
      }
    
      .home-login-button {
        width: 100%;
        height: 54px;
        margin-bottom: 20px;
      }

      .login-guest-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text_secondary_color;
        font-family: "Georgia";
        font-size: 18px;
        width: 100%;
        text-align: center;

        span {
          cursor: pointer;
        }

        > img {
          transform: rotate(-90deg);
          width: 15px;
          height: 7px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    
      .home-agree-button {
        background-color: rgba(0, 0, 0, 0.473);
        color: #D8AF6D;
        border: 1px solid #D8AF6D;
        width: 100%;
        text-align: center;
        height: 54px;
        border-radius: 2px;
        margin-bottom: 20px;
        margin-top: 25px;
        margin-bottom: 35px;
      }
    
      .forgot-password-link {
        cursor: pointer;
        font-size: 14px;
      }
    
      .home-login-create-account {
        background: linear-gradient($background_color_strikethrough, $background_color_strikethrough) center/100% 1px no-repeat;
        margin-top: 5px;
        margin-bottom: 25px;
        width: 100%;
        text-align: center;
    
        .home-login-create-account-text {
          padding: 0 10px;
          background-color: white;
          font-weight: 500;
        }
      }
    
      .home-login-signup-button {
        width: 100%;
        height: 54px;
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .slogen {
    font-size: 4vw;
  }
}

@media screen and (max-width: $break_point_mobile) {

  .main-screen {
    margin-top: unset;
  }

  .gallery {
    margin: 0 !important;
  }
  
  .gallery-texts-wrapper {
    padding: 0 10% !important;
  }

  .services {
    padding: 0 10% !important;
    margin: 0;

    .services-card-text {
      text-align: end;
    }

    .services-card-row {
      justify-content: space-between;

      img {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .why-simber-card {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .why-simber-header {
      padding-top: 20px;
    }
  }

  .small-accordion-card {
    width: 80% !important;
    margin-bottom: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .homepage-card-content {
    padding: 15px 11% !important;

    .title {
      margin: 25px 0 !important;
    }

    .title-devider {
      display: block !important;
      margin-bottom: 25px !important;
      align-self: flex-start !important;
      width: 40% !important;
    }

    button {
      margin-left: auto !important;
    }
  }

  .hompage-card-content-mid {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }

  .hompage-card-text {
    text-align: justify !important;
    width: 100% !important;
  }

  .covid-mobile {
    display: block;
  }

  .type-container {
    margin-bottom: 50px !important;
  }

  .home-login-header {
    text-align: center;
  }

  .slogen {
    font-size: 5vw;
  }

  .search-bar {
    width: 100%;
  }

  .home-login-dialog {
    position: relative;

    .login-dialog-close-button {
      position: absolute;
      right: 25px;
      top: 20px;
      display: block !important;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .login-close-button-hide {
    display: none;
  }
}

@media screen and (max-width: 928px) {
  .search-bar {
    .booking-card {
      .top-row {
        flex-direction: column;
        align-items: center;
    
        > input {
          width: 100% !important;
          max-width: 100% !important;
        }

        .search-input-wrapper {
          width: 100% !important;
          max-width: 100% !important;

          .search-input {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
        
        .hour-select {
          width: 100% !important;
          max-width: 100% !important;
        }
      }

      .bottom-row {
        flex-direction: column;
        row-gap: 15px;

        .offer-button {
          margin: 0 !important;
          width: 260px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .slogen {
    font-size: 6vw;
  }

  .home-login-dialog {
    padding: 0 10% !important;
  }
}

@media screen and (max-width: 526px) {
  .search-bar {
    padding: 10px !important;
    width: 98% !important;

    .booking-card {
      padding: 10px !important;

      .top-row .search-input-wrapper {
        min-width: unset !important;
      }
    }
  }

  .slogen {
    font-size: 8vw;
  }

  .open-button {
    font-size: 5vw;
    height: 60px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media screen and (max-width: 400px) { 
  .search-modal-wrapper .search-modal.types > img {
    height: 25px;
    width: 25px;
  }

  .slogen {
    width: 100%;
  }

  .open-button {
    height: 50px;
    width: 100%;
  }

  .home-login-dialog {
    padding: 0 15px !important;
  }
}