@import "../variables.scss";

.blog {
  a {
    text-decoration: underline;
  }

  .primary-button {
    a {
      text-decoration: none !important;
    }
  }

  .pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    padding-top: 50px;

    &:first-of-type {
      margin-bottom: 50px;
    }

    .MuiPaginationItem-text {
      color: $text_primary_color;
      font-family: "Gotham";
      font-size: 18px;
    }

    ul {
      margin-bottom: 0;
    }

    .Mui-selected {
      background-color: $button_background_color_dark !important;
      color: white !important;
    }

    .MuiPaginationItem-root {
      &:hover {
        background-color: $button_background_color_dark_hover;
      }

      @media screen and (max-width: 460px) {
        padding: 0 5px;
        min-width: 30px;
        height: 30px;
        border-radius: 15px;
      }
    }
  }

  .no-results-text {
    margin-left: auto;
    margin-right: auto;
  }

  h2 a {
    text-decoration: none !important;
  }

  .hide {
    display: none !important;
  }

  .placeholder-link{
    &:hover{
      text-decoration: none;
    }
  }

  .blog-wrapper{
    @include bgimage("./images/backgrounds/blog_background");

    &.service-picture::after {
      height: 208px;
      bottom: -1px;
      background-image: url("../images/backgrounds/service_page_background_bottom_high.png");

      @media screen and (min-width: 1921px) {
        background-image: url("../images/backgrounds/service_page_background_bottom_high_long.png");
      }
  }
  }

  .container {
    max-width: 90% !important;

    @media screen and (max-width: 760px) {
      max-width: 100% !important;
    }
  }

  h1 {
    margin-bottom: 45vh !important;

    @media screen and (min-height: 750px) {
      margin-bottom: 55vh !important;
    }

    @media screen and (min-height: 835px) {
      margin-bottom: 55vh !important;
    }

    @media screen and (min-height: 940px) {
      margin-bottom: 60vh !important;
    }
    
    @media screen and (min-height: 1000px) {
      margin-bottom: 65vh !important;
    }

    @media screen and (min-height: 1175px) {
      margin-bottom: 68vh !important;
    }

    @media screen and (min-height: 1200px) {
      margin-bottom: 70vh !important;
    }
  }

  .blog-h2 {
    color: white !important;
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: 1.5rem !important;
  }

  &.service-background-wrapper .service-page-wrapper ul li:first-child {
    margin-top: 0 !important;
  }
  &.service-background-wrapper .service-page-wrapper li {
    list-style-type: none;
    list-style-image: none;
    margin-bottom: 0;
  }

  .carousel {
    h2 {
      cursor: pointer;
    }
  }

  .react-multi-carousel-dot button {
      border-color: #D8AF6D;
      border-width: 3px;
      background-color: rgba(0, 0, 0, 0.432);
      transition: all 1s;
  }

  .react-multi-carousel-dot-list {
      bottom: -15px;
  }

  .react-multi-carousel-dot--active button {
      width: 60px;
      border-radius: 15px;
      background-color: #D8AF6D;
      margin: 0 15px;
      transition: all 1s;
  }

  @media screen and (max-width: 980px) {
    .posts{
      flex-direction: column;
      .post-card {
        flex-basis: 0 !important;
      }
    }
  }

  @media screen and (max-width: 760px) {

    .carousel {
      width: 100%;
    }

    .data {
      justify-content: center;
      margin-bottom: 0 !important;
    }
    
    .more-button {
      margin-left: auto !important;
      margin-right: auto !important;
      height: 35px !important;
    }

    h2 {
      padding-top: 25px !important;
      text-align: center !important;
    }

    .featured-posts-top {
      >p {
        display: none;
      }
    }

    .main-section {
      flex-direction: column !important;

      .posts {
        .post-card {
          max-width: 100% !important;
          padding: 15px 0 !important;

          >img {
            width: 100vw !important;
            max-width: none !important;
            margin-left: calc(calc(100% - 100vw) / 2);
          }
        }
      }
    }

    .sidebar {
      border-left: none !important;
      border-top: 1px solid #D8AF6D;
      padding-left: 0 !important;
      margin-top: 35px;
      padding-left: 2%;

      .search-bar {
        justify-content: center;
        margin-top: 50px;
      }
    }
  }

  .featured-posts-top {
    .data {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        margin-bottom: 30px;
      }

      .dot {
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        background-color: white;
        margin: 0 20px;
      }
    }

    p {
      max-width: 950px;
    }

    .more-button {
      margin: 25px 0;
    }
  }

  .main-section {
    display: flex;
    flex-direction: row;

    .posts {
      flex-basis: 80%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      row-gap: 50px;

      .post-card {
        flex-basis: 50%;
        padding: 0 5%;
        flex-grow: 1;

        h2 {
          margin-bottom: 25px;
          font-size: 33px;
          text-align: start;
          cursor: pointer;
        }

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
          cursor: pointer;
          max-height: 500px;
          object-fit: cover;
        }

        .more-button {
          margin-top: 15px;

          a {
            color: white;
          }
        }

        .data {
          display: flex;
          flex-direction: row;
          margin-top: 28px;
          margin-bottom: 30px;
          align-items: center;
          flex-wrap: wrap;

          &>div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 15px;
            margin-bottom: 10px;

            img {
              height: 28px;
              width: 28px;
              margin-right: 15px;
            }
          }
        }
      }
    }

    .sidebar {
      flex-basis: 20%;
      border: 1px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(to bottom, $gradient_color_dark, $gradient_color_light 99.59%);
      border-right: 0;
      border-right: 0;
      border-top: 0;
      padding-left: 2%;

      h2 {
        margin-bottom: 25px;
      }
    
      .search-bar {
        width: 100%;
        height: 54px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;

        input {
          outline: none !important;
          height: 54px ;
          background-color: white;
          padding-left: 25px;
          border-width: 1px;
        }

        .blog-search-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          height: 100%;
          width: 82px;
          margin-left: -20px;
          background-image: linear-gradient(175.53deg, $button_background_color_light -33.012%,$button_background_color_dark 186.907%);;
          cursor: pointer;
          box-shadow: 0 22px 20px -20px $button_background_color_dark;
        }
      }

      .featured-posts-sidebar {
        margin-top: 45px;

        h3 {
          font-size: 1.3rem;
        }

        .featured-post-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 27px;
          cursor: pointer;

          &:hover {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, $background_color_transparent 100%);
          }

          &>img {
            width: 40%;
            flex-basis: 40%;
            object-fit: contain;
            padding-right: 15px;
          }

          .data {
            flex-basis: 60%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            h3 {
              text-align: start;
            }

            &>div {
              display: flex;
              flex-direction: row;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .services-menu {
        margin-top: 45px;

        &>div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 5px;
          margin-bottom: 5px;
          cursor: pointer;

          &:hover {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, $background_color_transparent 100%);
          }

          img {
            transform: rotate(-90deg);
            width: 12px;
            height: 6px;
          }
        }
      }

      .tags-wrapper {
        margin-top: 45px;
        margin-bottom: 50px;

        .tags {
          display: flex;
          flex-wrap: wrap;

          .tag {
            color: #D8AF6D;
            margin-bottom: 15px;
            cursor: pointer;

            &.margin {
              padding-right: 20px;
              margin-right: 20px;
              border-right: 1px solid #D8AF6D;
            }
          }
        }
      }
    }
  }

  .tastemonial-card {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, $background_color_transparent 100%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    height: fit-content;

    .tastemonial-text {
      display: inline;
      margin-bottom: 20px;
      overflow: hidden;
      height: auto;
    }

    .tastemonial-name {
      font-family: "Gotham" !important;
      font-size: 22px;
      font-weight: bold;
      color: $text_secondary_color;
    }    
    
    img {
      margin: 15px 0;
      width: 97px;
      height: 18px;
    }
  }
}