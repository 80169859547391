@import "../variables.scss";

.small-accordion-card {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, transparent 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 30%;
    max-width: 400px;
    border-width: 2px;
    border-style: solid;
    border-bottom: 0;
    border-top: 0;
    border-color: $border_color_orange;

    .primary-button {
        width: 60%;
    }

    @media screen and (max-width: $break_point_mobile) {
        width: 100%;
        margin-bottom: 50px;

        .small-accordion-title {
            height: unset !important;
        }
    }

    @media screen and (min-width: 1539px) {
        .small-accordion-title {
            height: 67px !important;
        }
    }

    img {
        width: 117px;
        height: 117px;
        margin: 40px;
    }

    .small-accordion-title {
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        color: $text_secondary_color ;
        font-size: 28px;
        height: 100px;
        font-weight: 500 !important;
        width: 60%;
        text-align: center;
        margin-bottom: 40px;
    }

    .small-accordion-text {
        width: 90%;
        text-align: left;
        margin-bottom: 40px;
        line-height: 25px;
    }

    button {
        margin-top: auto !important;
        margin-bottom: 15px;
    }
}