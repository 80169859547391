@import "../variables.scss";

.reset-pass-header {
    margin-bottom: 55px !important;
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-size: 38px;
    color: $text_secondary_color;
    line-height: 40px !important;
}

.change-pass-button {
    height: 54px;
    width: 100% !important;
}

.success-card {
    padding: 50px 10% !important;
    text-align: center;

    a {
        margin-top: 0 !important;
    }

    a:hover {
        text-decoration: underline !important;
    }
}