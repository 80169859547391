@import "../../variables.scss";

.bookings-page-wrapper {

  .mobil-wrapper {
    @media screen and (min-width: 775px) {
      display: none;
    }

    .booking-card {
      background-color: white;
      padding: 25px;
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 25px;

      .cancelled {
        color: #FF0303;
        position: absolute;
        right: 25px;
        bottom: 25px;
      }

      .export {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 15px;
      }

      .data {
        display: flex;
        flex-direction: column;

        img {
          width: fit-content;
          margin: 15px 0;
        }
      }

      .date {
        color: $text_secondary_color;
        margin-bottom: 16px;
      }

      .origin {
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        font-size: 1.1rem;
      }

      a {
        margin-top: 15px;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        font-size: 15px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .table-wrapper {
    height: 500px;
    overflow-y: auto;

    a {
      font-size: 15px;
      font-family: Georgia, 'Times New Roman', Times, serif !important;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 775px) {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 6px;
    }
      
      /* Track */
    &::-webkit-scrollbar-track {
        background: #929292;
    }
      
      /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $text_secondary_color;
    }
      
      /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $text_secondary_color;
    }
  }

  .MuiTableCell-head {
    white-space: nowrap;
    background-color: $background_color_profile;
    border: none;
    padding-bottom: 20px;
    font-weight: normal;
    font-family: "Gotham";
    border-bottom: 1px solid black;
  }

  .MuiTableCell-root {
    border-bottom: 1px solid black;
  }

  .table-body {
    .MuiTableCell-root {
      padding: 15px 5px !important;
      color: $text_secondary_color;

      .view-more {
        white-space: nowrap;
        color: white !important;
        cursor: pointer;
        margin-right: 10px;

        img {
          transform: rotate(-90deg);
          width: 15px;
          height: 8px;
        }
      }

      .cancelled {
        color: #FF0303;
        margin-right: 8px;
      }
    }
  }

  .export-link {
    margin-left: auto;
    display: block;
    width: 100%;
    max-width: 370px;
    height: 41px;
    margin-top: 15px;

    @media screen and (max-width: 775px) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .no-bookings {
    @media screen and (min-width: 775px) {
      display: none;
    }
  }
}