@import "../../variables.scss";

.date-picker-wrapper {
    position: relative;
    margin-left: auto;

    @media screen and (max-width: 928px) {
        margin-left: 0 !important;
        width: 100% !important;

        .date-picker-input {
            width: 100% !important;
        }

        .date-picker-accordion { 
            width: 100% !important;
            padding: 15px !important;
        }
    }

    @media screen and (max-width: 827px) {
        .input-wrapper {
            justify-content: space-evenly !important;
        }

        .date-picker-accordion {
            row-gap: 20px !important;
            height: fit-content !important;

            .am-pm,
            .time-input {
                flex-basis: 100% !important;
            }
        }
        
        .submit-button {
            line-height: unset !important;
        }
    }

    .date-picker-input {
        width: 260px;
        background-color: white;
        color: $text_color_accent;
        padding: 0 20px;
        margin-right: 0 !important;
        height: 55px;
        border: 1px solid $border_color_dark;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.open {
            border: 3px solid $border_color_accent;
        }

        .rotate {
            transform: rotate(180deg);
            transition: 0.3s ease-in-out;
        }

        img {
            transition: 0.3s ease-in-out;
        }

        .unset {
            color: $text_primary_color;
        }
    }

    .date-picker-accordion {
        position: absolute;
        right: 0;
        bottom: 70px;
        width: 555px;
        height: 270px;
        background-color: white;
        z-index: 9999;
        border: 1px solid $border_color_light;
        padding: 10%;
        box-sizing: border-box;
        box-shadow: 0px 27px 99px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        column-gap: 20px;
        row-gap: 0px;
        flex-wrap: wrap;

        .input-wrapper {
            background-color: $input_background_color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            color: $text_primary_color;
            font-size: 24px;
            height: 60px;

            > img:not(.time-dots) {
                transform: rotate(180deg);
                width: 24px;
                height: 12px;
            }
        }

        .date-input {
            flex-basis: 100%;
            position: relative;

            > div {
                position: absolute;
                z-index: 1;
                top: 98px;
                left: 0;
                width: 100%;
                border: 1px solid $border_color_light;
                box-sizing: border-box;
                box-shadow: 0px 27px 99px 0px rgba(0, 0, 0, 0.16);
            }
            
            > img,
            > span {
                cursor: pointer;
            }

            .MuiPickersBasePicker-pickerView {
                width: 100%;
                max-width: 100%;
                min-width: 257px;
            }

            .MuiPickersStaticWrapper-staticWrapperRoot {
                min-width: 257px;
            }

            .MuiPickersDay-day {
                width: 30px;
                height: 30px;
            }

            .MuiPickersCalendar-week {
                justify-content: space-around;
            }

            .MuiPickersCalendarHeader-daysHeader {
                justify-content: space-around;

                span {
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
                    color: $text_color_accent;
                    font-size: 18px;
                }

            }

            .MuiPickersBasePicker-pickerViewLandscape {
                padding-bottom: 8px;
            }

            .MuiPickersToolbar-toolbar {
                background-color: rgb(31, 31, 31);
            }

            .MuiPickersDay-daySelected {
                background-color: $border_color_accent;
            }

            .MuiPickersDay-daySelected:hover {
                background-color: $border_color_accent;
                filter: brightness(0.9);
            }

            .MuiPickersClock-pin {
                background-color: $border_color_accent;
            }

            .MuiPickersClockPointer-pointer {
                background-color: $border_color_accent;
            }

            .MuiPickersClockPointer-thumb {
                background-color: $border_color_accent;
                border: 14px solid $border_color_accent;
            }
        }

        .time-input {
            flex-basis: 48%;
        }

        .MuiSelect-select {
            text-align: start;
            color: $text_primary_color;
            font-size: 24px;
            display: flex;
            align-items: center;
            font-family: "Gotham";
            line-height: 0;
            padding: 0;
            padding-right: 0 !important;
            min-height: 24px;
        }

        .am-pm {
            flex-basis: 48%;

            .am-pm-select {
                border-radius: 0;
                outline: none !important;
                box-shadow: none !important;
                width: 100%;
                height: 100%;

                .MuiInput-input {
                    background-color: unset !important;
                }

                img {
                    transform: rotate(180deg);
                    width: 24px;
                    height: 12px;
                    pointer-events: none;
                    position: absolute;
                    right: 0;
                }
            }
        }

        .submit-button {
            flex-basis: 100%;
            line-height: 0;
        }
    }
}