@import '../variables.scss';
.header-scroll {
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

a {
    color: $text_primary_color;
}

.header-container {
    display: flex !important;
    position: relative;
    z-index: 1;
    flex-direction: row !important;
    justify-content: space-between;
    background-image: url("../images/rectangle_white.png");
    background-repeat: repeat-x;
    background-position: center;
    min-height: 85px;
    text-wrap: nowrap;

    margin: 0 $main_padding_side;
    margin-top: 70px;

    .header-strip {
        position: absolute;
        z-index: 0;
        top: 41px;
        width: calc(100% - 40px);
        height: 95px;
    }

    .hide {
        display: none;
    }

    .image-placeholder {
        width: 220px;
        min-width: 140px;
    }

    .left-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
    }

    .right-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
    }

    @media screen and (max-width: $break_point_mobile) {
        margin-top: 0px;

        .image-placeholder {
            display: none;
        }
    
        .left-section {
            display: flex;
            flex-direction: column;
            justify-content: start;
            flex-grow: 0;
        }
    
        .right-section {
            display: flex;
            flex-direction: column;
            justify-content: start;
            flex-grow: 0;
        }

        &.open {
            background-image: url("../images/backgrounds/background_abstract_long_new.webp");
            background-size: cover;
            background-color: $background_color_light;
            height: fit-content;
            flex-wrap: nowrap !important;
            min-height: 100dvh;
            margin: 0;

            & .menu {
                display: flex !important;
            }
        }

        & .menu {
            display: none !important;
        }

        & .service-menu-mobil {
            display: flex!important;
            align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;
            border-top: 1px solid $gradient_color_dark;
            border-bottom: 1px solid $gradient_color_dark;
            margin-bottom: 15px;
            padding: 15px 0;
            color: $text_primary_color;

            .small-devider {
                display: block;
                height: 1px;
                width: 70%;
                background-color: $gradient_color_dark;
            }
        }

        & .services-menu-button {
            img {
                display: block !important;
                height: 5px;
                width: 10px;
                margin-left: 15px;
                margin-top: 4px;
            }
        }
        & .services-menu-button.open {
            span {
                border: none !important;
            }

            > span {
                color: $text_primary_color;
            }
            
            img {
                transform: rotate(180deg);
            }
        }
    }

    & .services-menu-button {
        img {
            display: none;
        }
    }

    & .service-menu-mobil {
        display: none;
    }

    .collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }
    
    .MuiButton-root {
        text-transform: none !important;
    }

    .active {
        >span:first-child a, >span:first-child {
            border-image: linear-gradient(90deg, $gradient_color_dark 0%, $gradient_color_light 100%) 100% 0;
            border-width: 1px;
            border-style: solid;
            border-top: none !important;
            font-weight: bold;
            color: $text_secondary_color !important;
        }
    }
    
    .email {
        padding-top: 15px;
        color: white;
        align-items: flex-end;

        @media screen and (max-width: $break_point_mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        span {
            min-width: fit-content;
        }
    }

    .start {
        position: absolute;
        left: 20px;
        
        img {
            width: 22px;
            height: 22px;
            margin: 10px;
            cursor: pointer !important;
        }

        @media screen and (max-width: $break_point_mobile) {
            width: 25%;
            flex-direction: row;
            top: 70px;
        }

        @media screen and (max-width: 325px) {
            flex-direction: column;
            top: 40px;
        }
    }

    .login-mobil {
        display: none;
        margin-top: 15px;
        margin-bottom: 30px;
        flex-direction: row !important;
    }

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 10vw;
        padding-right: 20px;
        flex-wrap: nowrap !important;
        width: inherit;
        max-width: 100%;

        .background {
            background-color: $background_color_white;
            height: 85px;
            width: 100vw;
        }

        .login {
            min-width: 160px;
            color: $text_primary_color;

            @media screen and (max-width: 1380px) {
                width: 130px;
                min-width: unset;
                text-wrap: wrap;
            }
        }
        @media screen and (min-width: 2300px) {
            padding-left: 8vw;
            
            .image-placeholder {
                width: 180px;
            }
        }

        .logout {
            cursor: pointer;
            color: $text_primary_color;
            font-family: "Gotham";
            font-size: 1.2rem;
            display: flex;

            .closed {
                display: flex;
                align-items: center;
            }

            img {
                margin-left: 4px;
                width: 16px;
                height: 8px;
            }

            .account {
                position: absolute;
                z-index: 50;
                top: calc(100% + 8px);
                right: 20px;
                display: flex;
                flex-direction: column;
                background-color: $background_color_white;
                padding: 28px;
                color: $text_primary_color;
                width: 241px;
                cursor: default;

                span {
                    cursor: pointer;
                    margin: 10px 0;
                }

                span.active {
                    font-weight: bold;
                    color: $text_secondary_color;
                }
            }
        }

        button {
            min-width: 50px;
            outline: none;
            font-family: "Gotham";
            font-weight: 500;
        }

        @media screen and (max-width: $break_point_mobile) {
            flex-direction: column;
            align-self: center;
            text-align: center;
            padding: 0;
            justify-content: flex-start;
            margin-top: 20% !important;

            button {
                min-width: 0;
                padding: 0;
                margin-bottom: 15px;
                font-size: 1.2rem;
            }

            .login {
                position: inherit;
            }

            .logout {
                position: static;
                align-items: center;
                
                &.desktop {
                    display: none !important;
                }

                .closed {                    
                    flex-direction: row;
                }

                .account {
                    position: static;
                    margin-top: 15px;
                }
            }
        }

        @media screen and ( max-width: 1299px) and (min-width: 1050px) {
            padding-left: 8vw;

            button {
                font-size: 1rem !important;
            }
        }

        @media screen and ( max-width: 1119px) and (min-width: 1070px) {
            padding-left: 8vw;

            button {
                font-size: 0.9rem !important;
            }
        }

        @media screen and ( max-width: 1070px) and (min-width: $break_point_mobile) {
            padding-left: 8vw;

            button {
                font-size: 0.8rem !important;
            }
        }
    }

    

    .navbar-toggler {
        color: white;
        border: none !important;
        position: absolute;
        right: 20px;
        top: 70px;

        &.collapsed .navbar-toggler-icon {
            background-image: url("../images/pictograms/menu_pictogram.png") !important;
            filter: brightness(0);
        }
        & .navbar-toggler-icon {
            background-image: url("../images/pictograms/close_pictogram_black.png") !important;
        }

        @media (min-width: $break_point_mobile) {
            display: none;
        }
    }    
    

    Button {
        padding: 10px 15px;
        font-size: 1.2rem;
        color: $text_primary_color;

        @media screen and (min-width: $break_point_mobile) {
            & .MuiButton-label:hover {
                border-bottom: 1px solid $gradient_color_dark;
            }
        }
    }
    .collapse {
        flex-basis: 100% !important;
        flex-wrap: wrap;
    }

    .header-img-collapsed {
        margin-left: 20px;
        margin-right: 30px;
        height: 130px;
        width: 130px;
        margin: 15px 0;
        display: none;
        border-radius: 50%;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: $break_point_mobile) {
            display: block;
        }
        
    }

    @media screen and (max-width: $break_point_mobile) {
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;
        margin-bottom: 25px;

        >div {
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            padding-right: 0px;
            width: auto;
        }
        .header-img {
            width: 150px;
            height: 150px;
        } 
        .header-title {
            font-size: 3rem;
        }    
        .end {
            margin-left: inherit;
        }
        .menu {
            width: inherit;
            margin: inherit;
        }
        .login-mobil {
            display: flex;
            position: absolute;
            bottom: -40px;

            .logout {
                position: relative;
                color: $text_color_gold;
                font-size: 1.1rem;
                cursor: pointer;
    
                .closed {
                    display: flex;
                    align-items: center;
                }
    
                img {
                    margin-left: 4px;
                    width: 16px;
                    height: 8px;
                }
    
                .account {
                    position: absolute;
                    z-index: 50;
                    top: 31px;
                    left: 25px;
                    display: flex;
                    flex-direction: column;
                    background-color: $background_color_white;
                    padding: 18px;
                    color: $text_primary_color;
                    font-family: "Gotham";
                    cursor: default;
    
                    span {
                        cursor: pointer;
                        margin: 10px 0;
                    }
    
                    span.active {
                        font-weight: bold;
                        color: $text_secondary_color;
                    }
                }
            }
        }
    }
}

.header-img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    inset: 0;

    @media screen and (max-width: $break_point_mobile) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.MuiList-root {
    background-color: $background_color_white;
    color: $text_primary_color;
    li a {
        font-family: "Gotham" !important;
    }

    li.active {
        font-weight: bold;
    }

    li:hover {
        .menu-item-text{
            border-bottom: 1px solid $gradient_color_dark;
        }
    }
}

.MuiListItem-root {
    &.active {
        font-weight: bold;
        color: $text_primary_color !important;
    }
}

.MuiPopover-paper {
    background-color: unset !important;
}

.service-menu {
    display: inherit;
    pointer-events: none;

    .MuiPopover-paper {
        pointer-events: all;
    }

    @media screen and (max-width: $break_point_mobile) {
        display: none;
    }   
}

.logout-modal {
    position: static;
    
    .MuiDialog-paper {
        background-color: $background_color_white;
        color: $text_primary_color;
        width: 40%;
        max-width: 785px;
        position: static;
        
        .makeStyles-root-3 {
            background-color: unset !important;
        }
    }

    .MuiBackdrop-root {
        background-color: unset;
    }

    .close-button {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 32px;
        height: 32px;
        cursor: pointer;    
    }

    .logout-dialog-content {
        position: static;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 45px 10%;
        padding-top: 45px !important;
        justify-content: center;
        align-items: center;
        font-family: "Gotham";

        @media screen and (max-width: $break_point_mobile) {
            padding: 45px 5%;
        }

        .name {
            color: $text_secondary_color;
            font-size: 34px;
            margin-top: 10px;
            font-family: Georgia, 'Times New Roman', Times, serif;
        }

        .logout-button {
            margin-top: 45px;
            height: 64px;
            width: 60%;
        }
    }   

    @media screen and (max-width: 692px) {
        .MuiDialog-paper {
            width: 80%;
        }
    }
}

.link {
    text-decoration: inherit !important;
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: inherit !important;
    }
}