@import "../variables.scss";
.footer {
    background-color: $background_color_dark;
    color: white;
    padding: 50px 20px;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    @media screen and (max-width: $break_point_mobile) {
        align-items: flex-start !important;

        .d-flex {
            align-items: flex-start;
        }

        .follow-section {
            flex-direction: row !important;

            .social {
                margin-left: 15px !important;
            }
        }
    }

    .white-link {
        color: $text_color_footer !important;
    }

    .d-flex {
        justify-content: flex-start;
    }
    img {
        height: 24px;
        margin-right: 16px;
        
    }

    a {
        color: $text_color_footer !important;
        text-decoration: none;
        margin: 6px 0;
        height: 24px;

        &:hover {
            text-decoration: none;
        }
    }

    .top-arrow {
        cursor: pointer;
        position: absolute;
        right: 5%;
        top: 40%;
        width: 40px;
        height: 52px;
    }

    .follow-section {
        display: flex;
        flex-direction: column;
    }
    .social {
        img {
            width: 22px;
            margin-right: 20px;
            cursor: pointer !important;
        }
    }

    @media screen and (max-width: 900px){
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .middle {
        margin: 0 6%;

        @media screen and (max-width: 900px){
            margin: 10px 0;
        }
    }

    span {
        margin: 4px 0;
        overflow-wrap: anywhere;        
    }

    .footer-header {
        font-size: 38px !important;
        font-family: Georgia !important;
        margin-bottom: 15px;

        @media screen and (max-width: 900px){
            overflow-wrap: normal !important;
        }
    }
}