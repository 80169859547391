@import "../../variables.scss";
.airport-wrapper {
    @include bgimage("./images/backgrounds/airport_background_light");
}

.jump-to-content-arrow {        
    position: absolute;
    background-image: url("../../images/pictograms/arrow_light.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: calc(100dvh - 120px - 22vh);
    z-index: 100;
    width: 100px;
    height: 20px;
    right: calc(50% - 50px);
    cursor: pointer;
}

.scroll-to-content-arrow {
    position: absolute;
    top: calc(100dvh - 220px - 12vh);
    z-index: 100;
    width: 75px;
    height: 24px;
    right: calc(50% - 43px);
    cursor: pointer;
}

.secondary-color {
    color: $text_secondary_color;
}

.service-picture {
    background-repeat: no-repeat;
    background-size: 100vw 80vh;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 0;
    height: 100dvh;
    width: 100vw;
    left: 0;
    z-index: 0;

    &::after {
        content: "";
        width: 100vw;
        height: 92px;
        display: block;
        position: absolute;
        bottom: -30px;
        background-size: cover;
        background-position: center;
        overflow: visible;
        background-image: url("../../images/backgrounds/service_page_background_bottom.png");

        @media screen and (min-width: 1921px) {
            background-image: url("../../images/backgrounds/service_page_background_bottom_long.png");
        }
    }
}

.service-background-wrapper {
    position: relative;
    z-index: 0;

    @media screen and (max-width: $break_point_mobile) {
        h1 {
            margin-top: 0 !important;
        }
    }

    .content-wrapper {
        z-index: 0;
        position: absolute;
        top: 100dvh;
        width: 100vw;
        height: 100%;
        background-color: $background_color_light;
        background-image: url('../../images/backgrounds/background_abstract_long_new.webp');
        background-size: 100vw 200vh;
    }

    .service-page-wrapper {
        background-repeat: no-repeat;
        background-size: auto 100dvh;
        overflow: hidden;
        position: relative;

        .container {
            a {
                color: $text_secondary_color;
                text-decoration: underline;
            }
            
            @media (max-width: 768px) {
                max-width: 720px;
            }

            .margin-top-sm {
                margin-top: 35px;
            }

            .top-img {
                z-index: 2;
                position: fixed;
                right: 80px;
                bottom: 80px;
                cursor: pointer;

                @media screen and (max-width: $break_point_mobile) {
                    display: none !important;
                }
            }

            >.content {
                position: relative;
                z-index: 1;
                margin-bottom: 150px;


                @media screen and (max-width: $break_point_mobile) {
                    z-index: 0 !important;

                    h1 {
                        font-size: 50px;
                    }
                }

                @media screen and (max-width: 500px) {
                    h1 {
                        font-size: 40px;
                    }
                }
            }
        }

        .large-image {
            margin-left: -10%;
            width: 120%;
            display: block;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        
        h1 {
            font-family: Georgia, 'Times New Roman', Times, serif !important;
            text-align: center;
            margin-bottom: 60vh;
            margin-top: 50px;
            height: 45px;

            @media screen and (min-height: 650px) {
                margin-bottom: 70vh;
            }

            @media screen and (min-height: 750px) {
                margin-bottom: 73vh;
            }

            @media screen and (min-height: 1000px) {
                margin-bottom: 78vh;
            }
        }

        .offset-text-before-title {
            position: relative;
            display: block;
            top: -20vh;
        }

        h2 {
            font-family: Georgia, 'Times New Roman', Times, serif !important;
            text-align: center;
            color: $text_secondary_color;
            font-size: 38px;
            margin-bottom: 45px;
            display: block;
            text-align: center;
            margin-right: auto;
            margin-left: auto;
        }

        h3 {
            font-family: Georgia, 'Times New Roman', Times, serif !important;
            font-size: 33px;
            text-align: center;
            color: $text_secondary_color;
            margin-bottom: 35px;
            text-align: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 45px;
        }

        .devider {
            width: calc(100%);
            height: 1px;
            background-image: linear-gradient(90.00deg, $gradient_color_dark, $gradient_color_light 99.59%);
            margin: 55px 0;
        }

        li {
            list-style-type: none;
            list-style-image: url("../../images/pictograms/list_item_solid.png");   
            margin-bottom: 5px;

            strong {
                color: $text_secondary_color;
            }
        }

        ul li:first-child {
            margin-top: 45px;
        }

        ul {
            margin-bottom: 45px;
        }

        ul li ul li:last-child {
            margin-bottom: 45px;
        }

        .sub-list-item {
            list-style-type: none;
            list-style-image: url("../../images/pictograms/sub_list_item.png");        
        }

        .picture-section {
            display: flex;
            margin: 45px 0;
            flex-wrap: wrap;
            width: 100vw;
            margin-left: calc(((1280px - 100vw)/2) - 24px);
            align-items: center;

            &.big {
                img {
                    width: 100%;
                }
            }

            &.small-text {
                align-items: center;
                justify-content: center;
                display: flex;
            }

            .content {
                padding-left: 4%;
                width: 25%;

                h3 {
                    text-align: start !important;
                }
                p {
                    text-align: start !important;
                }
            }

            img {
                width: 55%;
                object-fit: contain;            
            }

            @media screen and (max-width: 1280px) {
                margin-left: calc(((1140px - 100vw)/2) - 24px);
                .content {
                    width: 40%;
                }
            }

            @media screen and (max-width: 1200px) {
                margin-left: calc(((960px - 100vw)/2) - 24px);
            }

            @media screen and (max-width: $break_point_mobile) {
                margin-left: calc(((720px - 100vw)/2) - 24px);
            }

            @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: center;
                margin-left: 0;
                width: 100%;

                &.big {
                    width: 100vw;
                    margin-left: calc(((720px - 100vw)/2) - 24px);

                    img {
                        width: 100% !important;
                    }
                }

                img {
                    width: 90% !important;
                    margin-bottom: 15px;
                    margin-left: 0px;
                }

                .content {
                    width: 90% !important;
                    padding-left: 0 !important;

                    h3 {
                        text-align: center !important;
                    }
                }
            }

            @media screen and (max-width: 720px) {
                &.big {
                    width: 100vw;
                    margin-left: -24px;
                }
            }
        }

        .dotless-list {
            display: flex;
            flex-direction: column;

            .list-row {
                margin: 5px 0;
                display: flex;

                > span {
                    margin-right: 15px;
                    color:#D8AF6D;
                    display: block;
                }

                &:first-child {
                    margin-top: 20px;
                }
            }
        }

        .faq-wrapper {
            counter-reset: faqlist;
            margin-top: 50px;
        }

        .faq-section-header {
            margin-top: 150px;
            margin-bottom: 100px;

            @media screen and (max-width: $break_point_mobile) {
                margin-top: 25px !important;
                margin-bottom: 50px !important;
                padding-top: 0 !important;
            }
        }
    }
}