@import "../variables.scss";

.summary-header {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 38px;
    text-align: center;
    color: $text_secondary_color;
    margin-top: 40px;
    margin-bottom: 35px;
}

.header {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 13px;

    &.passenger-contacts {
        margin-bottom: 10px;
    }
    
    &.discount {
        font-weight: bold;
        margin-top: 10px;
    }
}

@media screen and (max-width: $break_point_mobile) {

    .header {
        justify-content: start;
    }

    .summary-header {
        margin-top: 0;
    }

    .container-card {        
        .contacts-row {
            flex-wrap: wrap;

            &.header {
                flex-direction: row;
                justify-content: flex-start !important;
            }
        }
    
        .summary-user-data {
            border-bottom: none !important;
            padding-bottom: 0 !important;
            width: auto !important;
            flex: 1 1 auto !important;
            flex-direction: column !important;

            &::after {
                width: 100% !important;
            }

           .flight-number {
                margin-top: 0 !important;
            }

            >.MuiGrid-root {
                width: 100%;
                margin-top: 0;
                margin-left: 0;
                flex-direction: column;
            
                .header {
                border-bottom: 1px solid $border_color_orange;
                border-right: none;
                min-width: 100%;
                padding-left: 0;
                padding-bottom: 10px;
                text-align: start;
                }
        
                .data {
                min-width: 100%;
                padding: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                }
            }

            .passenger-contacts-row { 
                margin-top: 0 !important;
                padding-top: 0 !important;
            }
        }
    
        .sumamry-booking-data {
            margin-top: 10px !important;
            width: auto !important;
            flex: 1 1 auto !important;
            flex-direction: column !important;

            .price {
                margin-top: 0 !important;
            }
    
            >.MuiGrid-root {
                width: 100%;
                margin-top: 0;
                margin-left: 0;
                flex-direction: column;
    
                .header {
                    border-bottom: 1px solid $border_color_orange;
                    border-right: none;
                    min-width: 100%;
                    padding-left: 0;
                    padding-bottom: 10px;
                    text-align: start;
                }
        
                .data {
                    min-width: 100%;
                    padding-left: 0;
                    padding-bottom: 10px;
                }
            }
        }
    
        .discount-input {
          font-size: normal !important;
          flex-grow: 1;
        }
        .discount-button {
          margin: 0 !important;
        }
        .pay-button {
          width: 100% !important;
          margin-top: 65px !important;
        }
      }
}

.container-card {
    margin: 25px;
    padding: 30px 5% !important;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: "Gotham";
    background-color: white;
    line-height: 23px;

    .summary-user-data {
        display: flex;
        width: 100%;
        position: relative;
        //border-bottom: 1px solid $border_color_orange;
        //bottom-border
        &::after {
            content : "";
            position: absolute;
            right: unset;
            left: 0;
            bottom: 0;
            height: 1px;
            width: calc(100% - 5px);
            border-bottom: 1px solid $border_color_orange;
        }
        padding-bottom: 15px;

        .header {
            min-width: 100px;
            max-width: 135px;
            text-align: end;
            border-right: 1px solid $border_color_orange;
            padding-right: 25px;
            color: $text_secondary_color;
            line-height: 18px;
        }

        .data {
            max-width: calc(100% - 100px);
            padding-left: 25px;
        }

        .contacts-row {
            display: flex;
            align-items: center;

            &.header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .phone {
                padding-right: 35px;
                border-right: 1px solid $border_color_orange;
                display: inline-block !important;
            }

            .email {
                display: inline-block !important;
                padding-left: 30px;
            }

            .im-traveling {
                display: inline-flex;
                align-items: center;
            }

            // .MuiCheckbox-root {
            //     color: #D8AF6D;
            // }
            // .MuiCheckbox-root:hover {
            //     background-color: #d8af6d15;
            // }
            // .MuiIconButton-label {
            //     color: #D8AF6D;
            // }

            @media screen and (max-width: 647px) {
                .phone {
                    display: block !important;
                    border-right: none;
                }

                .email {
                    padding-left: 0;
                }
            }
        }

        .flight-number {
            width: 100%;
            height: 35px !important;
            margin-top: 10px;
            outline: none !important;
            padding-left: 16px;
        }

        .passenger-contacts-row {
            display: flex;
            width: 100%;
            margin-top: 6px;

            input {
                border: 1px solid $border_color_dark;
                outline: none !important;
            }

            .name-input {
                width: 50%;
                height: 35px !important;
                margin-right: 20px;
                padding-left: 16px;
            }                

            .react-tel-input {
                width: 50%;
                height: 35px !important;
                margin-bottom: 0;

                .form-control {
                    height: 35px !important;
                }
            }

            @media screen and (max-width: 647px) {
                flex-direction: column;
                .name-input {
                    width: 100%;
                    height: 35px !important;
                    margin-right: 0;
                }

                .react-tel-input {
                    margin-top: 15px;
                    width: 100%;
                }
            }
        }        
    }

    .sumamry-booking-data {
        margin-top: 30px;
        display: flex;
        width: 100%;
        line-height: 36px;

        .header {
            color: $text_secondary_color;
            min-width: 100px;
            max-width: 135px;
            text-align: end;
            border-right: 1px solid $border_color_orange;
            padding-right: 25px;
            line-height: 18px;
        }

        .data {
            max-width: calc(100% - 100px);
            padding-left: 25px;
        }

        .comment-row {
            position: relative;
            margin-top: 10px;
            .comment {
                width: 100%;
                background-color: white;
                border: 1px solid $border_color_dark;
                font-size: 12px;

                legend {
                    color: black !important;
                    
                    span {
                        color: black !important;
                    }
                }
                
                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .comment-length {
                position: absolute;
                font-size: small;
                right: 10px;
                bottom: 10px;
                margin: 0;
                font-weight: normal;
            }
        }

        .price {
            margin-top: 10px;
        }

        .discount-wrapper {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-top: 10px;

            .discount-input {
                border: 1px solid $border_color_dark;
                height: 35px !important;
                padding-left: 10px;
            }

            .discount-button {
                height: 35px;
                width: 87px;
                margin-right: 10px;
                margin-top: 15px;
                padding: 0 !important;
                margin-left: 8px !important;
            }

            .pay-button {
                width: 250px;
                height: 35px;
                margin-left: auto;
                font-size: 16px;
                padding: 0 !important;
            }

            @media screen and (max-width: 727px) {
                .pay-button {
                    margin-top: 15px
                }                    
            }
            @media screen and (max-width: 400px) {
                .pay-button {
                    margin-top: 15px;
                    margin-left: 0;
                }

                .discount-input {
                    font-size: smaller;
                    max-width: 100%
                }
            }
            @media screen and (min-width: 540px) {
                .discount-button {
                    margin-top: 0px !important;
                }
            }
        }
    }
}

.terms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 35px;
}

.terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    .terms-link {
        color: $text_color_gold !important;
        text-decoration-line: underline !important;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .terms-link:hover {
        cursor: pointer !important;
        filter: brightness(0.9);
    }

    .required {
        color: red;
    }
}

.terms-dialog {

    .MuiBackdrop-root {
        background-color: unset;
    }

    .MuiDialog-paper {
        background-color: $background_color_light;
        background-image: url("../images/backgrounds/background_abstract_long_new.webp");
        background-position: top;
        background-size: cover;

        button {
            outline: none;
            margin: 0 15px;
            margin-bottom: 10px;

            @media screen and (max-width: 540px) {
                & {
                    width: 100%;
                }
            }
        }

        // .MuiDialogContent-root {

            // &::-webkit-scrollbar {
            //     width: 3px;
            //     height: 6px;
            // }
                
            //     /* Track */
            // &::-webkit-scrollbar-track {
            //     background: #3E3E3E;
            // }
                
            //     /* Handle */
            // &::-webkit-scrollbar-thumb {
            //     background: #D8AF6D;
            // }
                
            //     /* Handle on hover */
            // &::-webkit-scrollbar-thumb:hover {
            //     background: #ac8445;
            // }
        // }
    }
}