@import "../variables.scss";

.register-card {
    padding: 50px 12%;
    margin: 50px 0;
    background-color: white;
    border: 1px solid $orange_color;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 530px){
        padding: 50px 5%;
    }

    > span {
        margin-bottom: 5px;
    }

    .signup-button {
        width: 100%;
        height: 60px;
        margin-bottom: 25px;
    }

    .signup-link {
        a {
            margin: 0;
            margin-left: 5px;
        }
    }

    .register-text-input {
        height: 54px;
        background-color: white;
        width: 100%;
        border: 1px solid $border_color_dark;
        color: $text_color_accent;
        padding-left: 18px;
        outline: none;
    }

    .MuiFilledInput-root {
        background-color: white !important;
        border-radius: 15px;
    }
    .MuiOutlinedInput-root {
        input {
            border: none !important;
        }
    }

    a {
        margin: 20px 0;
    }
}

.MuiFilledInput-input:-webkit-autofill {
    border-radius: inherit;
}

// .react-tel-input {
//     width: 100%;
//     height: 56px;
//     border-radius: 15px;

//     .form-control {
//         width: 100% !important;
//         height: 56px !important;
//         border-radius: 15px !important;
//     }
//     .flag-dropdown {
//         background-color: white !important;
//         border: none !important;
//         border-radius: 15px !important;

//         .country-list {

//             .search-emoji {
//                 display: none;
//             }
//             .search-box {
//                 width: 95%;
//             }
//             color: black;
//             @media screen and (min-width: 400px) {
//                 margin: 2px 15px;
//             }
//             margin: 2px 0;
//             max-width: 80vw !important;
//         }

//         .selected-flag {
//             background-color: white !important;
//             border: none !important;
//             border-radius: 15px !important;

//             .flag {
//                 transform: scale(1.3);
//             }
//         }

//         &:hover {
//             border-radius: 15px;
//         }
//     }
// }

.feature-card-header {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    width: 100%;
    font-size: 38px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
    color: $text_secondary_color;
}

.features-card {
    width: 80%;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, transparent 100%);
    padding: 2%;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    margin-bottom: 60px;
    
    .feaute {
        flex-grow: 1;
        margin: 15px;
        min-width: 257px;
        flex-basis: 30%;

        .feature-header {
            color: $text_primary_color;
            font-family: "Georgia";
            display: block;
            font-size: 33px;
            margin-bottom: 25px;
        }

        @media screen and (min-width: 773px){
            &:last-child {
                flex-grow: 0;
            }
        }
        @media screen and (max-width: 335px){
            min-width: 90%;
        }
    }
}