@import "../../variables.scss";

.longterm-wrapper {
    @include bgimage("./images/backgrounds/long_term_background");
}

.row-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    &.list {
        ul {
            width: 250px !important;
        }
        margin-bottom: 100px !important;
    }
}