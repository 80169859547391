@import "../../variables.scss";

.service-page-wrapper {
    min-height: 100dvh;
}

.vehicles-img {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.vehicles-wrapper {
    @include bgimage("./images/backgrounds/vehicles_background");
}

.vehicles-container {
    @media (min-width: 1200px) {
        max-width: 1920px;
    }
}

@media screen and (max-width: $break_point_mobile) {
    .vehicle-page-slogen {
        padding: 0 10% !important;
        height: calc(100dvh - 215px) !important;
    }

    .vehicles-header {
        margin-bottom: 25px !important;
    }

    .vehicle-card {
        flex-direction: column !important;

        .vehicle-card-content {
            width: 100% !important;
        }

        .vehicles-image-wrapper {
            width: 100% !important;
            margin-top: 25px;

            button {
                margin-top: 55px !important;
            }
        }
    }

    .vehicle-image {
        padding: 0 !important;
    }

    .vehicle-image {
        filter: none !important;
    }
}

    .vehicle-page-slogen {
        height: calc(100dvh - 190px);
        padding: 0 15%;
        display: flex;
        flex-direction: column;
        position: relative;

        .vehicles-header {
            text-align: center;
            margin-bottom: 100px;
        }

        img {
            position: absolute;
            bottom: 58px;
            width: 75px;
            cursor: pointer;
            left: calc(50% - 32px);
        }
    }

    .vehicles-page-cards {
        padding: 0 8%;
        padding-top: 50px;
        font-family: "Gotham";

        .vehicle-card {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            border: 1px solid;
            border-image-slice: 1;
            border-width: 1px;
            border-image-source: linear-gradient(to right, $gradient_color_dark, $gradient_color_light 99.59%);
            border-left: 0;
            border-right: 0;
            border-top: 0;
            margin-top: 20px;
            padding-bottom: 30px;

            &:last-child {
                border: none;
            }

            .vehicle-card-content {
                width: 45%;
                display: flex;
                flex-direction: column;
                margin-right: 35px;

                a {
                    color: $text_color_gold;
                    text-decoration: underline;
                }

                .vehicle-header {
                    font-size: 38px;
                    text-align: start;
                    color:  $text_secondary_color;
                    font-family: Georgia, 'Times New Roman', Times, serif !important;
                    margin-bottom: 20px;
                    margin-right: unset;
                    margin-left: unset;
                }

                .vehicle-sub-header {
                    font-weight: bold;
                    margin-bottom: 20px;
                    font-size: 22px;
                    margin-right: unset;
                    margin-left: unset;
                    text-align: start;
                    font-family: "Gotham" !important;
                }

                .vehicle-stats {
                    margin-top: 20px;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    flex-wrap: wrap;

                    .vehicle-stat {
                        display: flex;
                        align-items: center;
                        padding-top: 10px;

                        img {                            
                            padding-right: 15px;
                            height: 51px;                            
                        }

                        &:not(:first-child) {
                            img {
                                padding-left: 10px;
                            }                            
                        }
                    }
                }

                .vehicle-prices {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .vehicle-prices-row {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 7px;

                        .vehicles-price {
                            font-weight: bold;
                            font-size: 18px;
                            color: $text_secondary_color;
                        }
                    }

                    .vehicles-devider {
                        width: 100%;
                        height: 1px;
                        background-color: $button_background_color_dark;
                        margin-bottom: 10px;
                    }
                }

                .vehicle-inquiry {
                    margin-top: 20px;
                    font-weight: 800;
                    cursor: pointer;
                    
                    img {
                        transform: rotate(-90deg);
                        width: 24px;
                        height: 12px;
                    }
                }
            }

            button {
                width: 100%;
                height: 57px;
                margin-top: 20px;
                margin-bottom: 40px;
            }

            .vehicle-image {
                border: none;
                object-fit: contain;
                width: 50%;
            }

            .vehicles-image-wrapper {
                width: 55%;
                display: flex;
                flex-direction: column;

                .vehicle-image {
                    width: 100%;
                    padding: 0 30px;
                    margin-top: 0;
                }
            }
        }
    }