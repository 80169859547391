@import "../../variables.scss";


.about-wrapper {
    @include bgimage("./images/backgrounds/about_us_background_new");

    &.service-picture::after {
        background-image: url("../../images/backgrounds/service_page_background_bottom.png");

        @media screen and (min-width: 1921px) {
            background-image: url("../../images/backgrounds/service_page_background_bottom_long.png");
        }
    }
}

.about-content {

    @media screen and (max-width: 800px) {
        p {
            text-align: justify;
        }
    }

    .b {
        margin: 30px 0;
    }

    .about-header {
        text-align: center !important;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        font-size: 3.5rem !important;
        margin-bottom: 35px !important;
        margin-top: 50px;
        display: block;

        @media screen and (max-width: $break_point_mobile) {
            margin-top: 0 !important;
        }
    }

    .about-text {
        line-height: 1.5;
    }

    .first-paragraph {
        margin-top: calc(100dvh - 250px);
    }

    .third-paragraph {
        margin-bottom: 40px;
    }
    
    .divider {
        margin-top: -15px;
        margin-bottom: 30px;
        background-repeat: no-repeat;
        background-image: linear-gradient(90deg, $gradient_color_dark 0%, $gradient_color_light 100%);
        background-size: 100% 2px;
        background-position: center;
        height: 1px;
    }

    .last-paragraph {
        color:  $text_secondary_color;
        font-size: 33px;
        line-height: 50px !important;
        font-weight: 400;
        margin-top: 50px;
        margin-bottom: 80px !important;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        margin-bottom: 200px;
    }
}