@import "../variables.scss";

.wrapper {
    background-image: url('../images/backgrounds/background_abstract_long_new.webp');
    background-color: $background_color_light;
    background-position: top;
    background-size: 100vw 200vh;
}

.login-card-wrapper {
    max-width: 685px !important;
}

.login-card {
    padding: 50px 20%;
    margin-top: 50px;
    margin-bottom: 150px;
    background-color: white;
    width: 100%;
    border: 1px solid $orange_color;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-button {
        width: 100%;
        height: 55px;
    }

    .login-link {
        margin: 15px auto;
    }

    .login-text-input {
        height: 54px;
        background-color: white;
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid $border_color_dark;
        color: $text_color_accent;
        padding-left: 18px;
    }

    @media screen and (max-width: 530px){
        padding: 50px 5%;
    }

    > span {
        margin-bottom: 25px;
    }
    
    .success {
        font-size: 38px;
        line-height: 40px !important;
        color: $text_secondary_color;
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin-top: 25px;
    }

    .MuiFilledInput-root {
        background-color: white !important;
        border-radius: 15px;
    }
    .MuiOutlinedInput-root {
        input {
            border: none !important;
        }
    }

    a {
        margin-top: 30px;
        margin-bottom: 10px;
        color: $text_secondary_color;
        cursor: pointer;
    }
}

.email-confirm-dialog {
    .MuiDialog-paper {
        width: 100%;
        background-color: white;
        border: 2px solid $orange_color;
    }

    .MuiDialogContent-root {
        margin-bottom: 25px;
        
        >p {
            color: $text_primary_color !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: "Gotham" !important;
        }
    }

    .MuiDialogActions-root {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
    }
    
    .MuiDialog-container {
        background-color: #000000aa;
    }
}

.sign-in-header {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    margin-bottom: 15px !important;
    font-size: 38px;
    color: $text_secondary_color;
    line-height: 40px !important;
    text-align: center;
}

.MuiFilledInput-input:-webkit-autofill {
    border-radius: inherit;
}