@import "../variables.scss";

.breadcrumbs-container {
    list-style: none;
    z-index: 99;
    position: relative;
    margin-bottom: 0;
    margin-left: $main_padding_side;

    @media screen and (min-width: $break_point_mobile) {
        margin-top: 25px;
    }

    @media screen and (max-width: $break_point_mobile) {
        font-size: 13px;
    }

    li {
        display: inline-block;
        margin-right: 10px;

        a {
            color: rgb(243, 243, 243) !important;
            text-decoration: underline;

            &.no-navigate {
                pointer-events: none;
                text-decoration: none;
            }
        }

        .separator {
            color:$text_color_gold;
            margin-left: 10px;
        }
    }
}