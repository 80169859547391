@import "../variables.scss";

.contact-us-form {

    .MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiSelect-outlined {
        border-radius: 0;
        outline: none !important;
        border: none !important;
    }

    .MuiOutlinedInput-input {
        background-color: white !important;
    }

    &.MuiContainer-maxWidthMd {
        @media screen and (min-width: 600px) {
            max-width: 775px !important;
        }
    }

    h1 {
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        color: $text_secondary_color;
        margin-bottom: 100px;
        font-size: 61px;
    }

    .sub-header {
        display: block;
        padding-bottom: 35px;
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, $gradient_color_dark, $gradient_color_light 99.59%);
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }

    .form-header {
        margin: 45px;
        display: block;
    }    

    input, select {
        outline: none;
        height: 54px !important;
        background-color: white;
        padding: 5px;
        padding-left: 15px;
        width: 100%;
        margin-bottom: 25px;
        border: none;

        &::placeholder {
            color: $text_color_placeholder;
        }
    }

    .select-icon-image {
        margin-right: 32px;
        width: 16px;
        height: 8px;
    }

    .message-wrapper {
        position: relative;

        .MuiFilledInput-multiline textarea:first-child {
            padding-right: 75px !important;
        }

        .counter {
            position: absolute;
            bottom: 25px;
            right: 30px;
            color: $text_color_placeholder;
            font-size: 10px;
        }
    }

    .MuiInputLabel-root {
        color: $text_color_placeholder;
    }

    .MuiFormControl-root.MuiTextField-root {
        background-color: white;
        width: 100%;
        margin: 25px 0;
    }

    .message-lf {
        .MuiFormControl-root.MuiTextField-root {
            margin-top: 0;
        }
    }

    .MuiFilledInput-root {
        background-color: white !important;
        &::before {
            border: none !important;
        }

        &::after {
            border: none !important;
        }

        .MuiFilledInput-input {
            margin-bottom: 15px;
            &::placeholder {
                color: $text_color_placeholder;
                opacity: 1;
            }
        }
    }

    .MuiFormControl-root.select-control {
        background-color: white;
        width: 100%;

        label {
            color: $text_color_placeholder;
        }
        .MuiSelect-outlined.MuiSelect-outlined {
            padding-top: 24px;
        }        
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
    }

    .contact-button {
        margin-bottom: 45px;
    }

    h2 {
        padding-top: 90px;
        margin-bottom: 60px;
        color: $text_secondary_color;
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, $gradient_color_dark, $gradient_color_light 99.59%);
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        font-size: 38px;
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
}