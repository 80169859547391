@import "../../variables.scss";

.profile-card  {
    width: calc(100% - (2 * $main_padding_side));
    border: 1px solid $orange_color;
    margin: 0 auto;
    padding: 35px 95px;
    padding-bottom: 80px;
    margin-bottom: 50px;
    margin-top: 55px;
    background-color: white;
    color: $text_primary_color;

    .updated-text {
        color: $text_secondary_color;
        font-size: 18px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 775px) {
        padding: 35px 5%;        

        .profile-card-content {
            flex-direction: column;

            .content {
                height: 100%;
                width: 100% !important;
                min-height: 300px !important;
            }
        }
    }

    .profile-header {
        font-size: 38px;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 40px;

        >span {
            color: $text_secondary_color;
        }
    }

    .profile-card-content {
        display: flex;

        .menu-hamburger {
            @media screen and (min-width: 775px) {
                display: none;
            }

            @media screen and (max-width: 775px) {
                width: 60% !important;
            }
            width: 25%;
            display: flex;
            flex-direction: column;            

            .profile-menu-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                &.first {
                    order: 1;
                }

                .tab-link {
                    cursor: pointer;

                    &.active {
                        color: $text_secondary_color;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }

                img {
                    height: 8px;
                    width: 16px;
                    margin-left: 15px;
                }
            }
        }
        
        .menu {
            width: 18%;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 775px) {
                display: none;
            }

            .profile-menu-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                .tab-link {
                    cursor: pointer;

                    &.active {
                        color: $text_secondary_color;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }

                img {
                    transform: rotate(-90deg);
                    height: 8px;
                    width: 16px;
                    margin-right: 15px;
                }
            }
        }

        .content {
            width: 82%;
            height: 100%;
            border: 1px solid $orange_color;
            background-color: $background_color_profile;
            padding: 5% 2% 2% 2%;
            min-height: 450px;
            
            @media screen and (max-width: 775px) {
                padding: 5%;
            }

            .edit-button {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                max-width: 370px;
                height: 41px;
             }
        }
    }

    .text-input {
        display: block;
        background-color: white;
        border: 1px solid transparent;
        padding: 5px 10px;
        height: 54px;
        max-width: 370px;
        width: 100%;
        outline: none;
        margin-bottom: 15px;
    }
}