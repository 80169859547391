@import "../variables.scss";
.homepage-card-wrapper:first-child {
    margin-top: 0 !important;
}

.homepage-card-wrapper {
    display: flex;
    flex-direction: row;
    margin: 50px 0;

    @media screen and (max-width: $break_point_mobile){
        flex-direction: column;
    } 
    @media screen and (max-width: 1625px){
        margin: 0;
    }    

    img {
        width: 40%;
        height: auto;
        max-height: 50vh;
        object-fit: contain;

        @media screen and (max-width: $break_point_mobile){
            width: 100%;
        }
    }

    .homepage-card-content {
        width: 60%;
        display: flex;
        flex-direction: column;
        padding: 20px 60px;

        @media screen and (max-width: 720px){
            flex-direction: column;
            padding: 10px;
        }

        .title {
            font-size: 2rem;
            font-family: Georgia, 'Times New Roman', Times, serif !important;
            margin: 25px 0;
            color: $text_secondary_color;
        }

        a {
            display: block;
            margin-top: 30px !important;
            margin-left: 0;
            margin-right: auto;
            margin-bottom: 15px;

            &:hover {
                background-color: #D8AF6D;
                color: black;
            }
        }

        @media screen and (max-width: $break_point_mobile){
            width: 100%;

            a {
                margin-left: auto;
            }
        }

        .hompage-card-content-mid {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;            

            .title-devider {
                width: 13%;
                background-color: $orange_color;
                height: 2px;

                @media screen and (max-width: 720px){
                    flex-direction: column;
                    display: none;
                }
            }
            .hompage-card-text {
                font-weight: 400;
                text-align: start;
                width: 80%;
            }
        }

        &.odd {
            .hompage-card-content-mid {
                flex-direction: row;
            }
            .hompage-card-text {
                text-align: end;
            }
            align-items: flex-end;
            a {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }   
}