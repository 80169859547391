@import "../../variables.scss";

.service-terms-component {
    font-family: "Gotham";
    line-height: 30px;

    table, td, th {
        border: 1px solid black;
    }

    th,td {
        padding: 5px;
    }

    h1 {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 100px;
        margin-top: 20px;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        color: $text_secondary_color;
    }

    h2 {
        text-align: center;
        display: block;
        width: 100%;
        margin: 55px 0;
        margin-bottom: 45px;
        font-size: 38px;
        color: $text_secondary_color;
        font-family: Georgia, 'Times New Roman', Times, serif !important;
    }

    p {
        color: $text_primary_color;
    }

    ol {
        li {
            padding-left: 15px;
            margin-bottom: 20px;
            color: $text_primary_color;

            &::marker {
                color: $text_secondary_color;
            }
        }
        ol {
            list-style: none;
            counter-reset: list;
        }
        ol > li {
            display: flex;
           &:before {
            color:$text_secondary_color;
            display: block;
            margin-right: 15px;
            content: counter(list, lower-alpha) ") ";
            counter-increment: list;
          }
        }
    }
}