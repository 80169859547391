@import './variables.scss';

body {
  position: relative;
  min-height: 100dvh;
  height: 100%;
  margin: 0;
  font-family: "Gotham";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}

a {
  text-decoration: underline;
}

.wrapper {
  height: 100%;
  position: relative;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html {
  height: fit-content;
  min-height: 100dvh;
  position: relative;  
}

#root {
  position: relative;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App, .wrapper{  
  height: fit-content;
  min-height: 100dvh;
}

.cookie {
  height: fit-content;
  min-height: 150px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background_color_cookie;
  padding: 25px 20%;
  position: fixed;
  bottom: 0;
  z-index: 9999;
}

.cookie button {
  margin-left: 25px;
}

.cookie button:hover {
  background-color: #D8AF6D;
  color: black;
}
